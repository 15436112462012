import { useQuery } from '@apollo/client';
import { useLazyQuery } from '@apollo/client';
import { SearchIcon } from '@heroicons/react/outline';
import { FilterIcon } from '@heroicons/react/solid';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import { QueryCurrentOrg } from '../../../graphql/people';
import { QueryAllCert } from '../../../graphql/certifications';
import { useAuth } from '../../../lib/authContext';
import { getSections } from '../../../lib/getNameSections';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import SnapshotCert from '../../../custom-prebuilt/snapshot/SnapshotCert.component';
import { toggleSlideOver } from '../../../redux/slideOverSlice';
import SlideOver from '../../../custom-prebuilt/SlideOver.component';
import SearchLogic from '../../../custom-prebuilt/SearchLogic.component';
import { orgCheck } from '../../../lib/orgCheck';

const CertsDirectoryAdminView = () => {
  const { cognitoID } = useAuth();
  const [selectedCert, setSelectedCert] = useState(null);
  const [certsDirectory, setCertsDirectory] = useState([]);
  const [initAllCertData, setInitAllCertData] = useState(null);
  const [parent] = useAutoAnimate({ duration: 150 });
  const [currentOrg] = useLazyQuery(QueryCurrentOrg);
  const allCerts = useQuery(QueryAllCert);
  const defaultOrg = useSelector((state) => state.org.defaultOrg);
  const dispatch = useDispatch();
  const show = useSelector((state) => state.slideOver.show2);
  const target = useSelector((state) => state.slideOver.profileTarget);
  const [searchInput, setSearchInput] = useState('');

  useEffect(() => {
    currentOrg({ variables: { where: { cognitoID: cognitoID || '' } } }).then((res) => {
      if (orgCheck(res, defaultOrg) && allCerts.data?.certifications) {
        setCertsDirectory([
          ...getSections(
            [...allCerts.data.certifications].sort((a, b) => (a.name < b.name ? -1 : 1)),
          ),
        ]);
        setInitAllCertData(allCerts);
      }
    });
  }, [cognitoID, defaultOrg, allCerts.data, allCerts, currentOrg]);

  useEffect(() => {
    allCerts.data && !selectedCert
      ? setSelectedCert(
          [
            ...getSections(
              [...allCerts.data.certifications].sort((a, b) => (a.name < b.name ? -1 : 1)),
            ),
          ][0]?.data[0],
        )
      : setSelectedCert(
          allCerts.data?.certifications.find(
            (c) => c?.name === selectedCert?.name || c?.description === selectedCert?.description,
          ),
        );
  }, [allCerts?.data, selectedCert]);

  const handleInputChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    initAllCertData.data?.certifications &&
      e.target.value === '' &&
      setCertsDirectory([
        ...getSections(
          [...initAllCertData.data.certifications].sort((a, b) => (a.name < b.name ? -1 : 1)),
        ),
      ]);
    initAllCertData.data?.certifications &&
      e.target.value !== '' &&
      setCertsDirectory([
        ...getSections(
          initAllCertData.data.certifications.filter((cert) =>
            cert.name.toLowerCase().includes(e.target.value.toLowerCase()),
          ),
        ),
      ]);
  };

  const handleSelectCert = (cert) => {
    setSelectedCert(cert);
    dispatch(toggleSlideOver({ show2: true, profileTarget: cert }));
  };

  const handleCloseSlideOver = () => {
    dispatch(toggleSlideOver({ show2: false }));
    setTimeout(() => {
      toggleSlideOver({ profileTarget: null });
    }, 300);
  };

  return (
    <div className="relative z-0 flex flex-1 flex-col lg:flex-row h-full">
      <div ref={parent} className="flex flex-grow">
        <aside className="max-w-[20rem] 2xl:max-w-sm min-w-[20rem] 2xl:min-w-[24rem] flex-1 flex-grow border-r border-gray-200 xl:order-first lg:flex lg:flex-col">
          <div className="lg:px-6 pt-6 pb-4">
            <h2 className="text-sm font-medium text-gray-900">Certifications Directory</h2>
            <p className="mt-1 text-xs text-gray-600">Select a certification to view its details</p>
            <form className="mt-2 flex space-x-4" action="#">
              <div className="min-w-0 flex-1">
                <label htmlFor="search" className="sr-only">
                  Search
                </label>
                <div className="relative rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="search"
                    name="search"
                    id="search"
                    className="block w-full rounded-md border-gray-300 pl-10 focus:border-signature focus:ring-signature
                                        text-xs"
                    placeholder="Search"
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-gray-300 bg-gray-800 px-3.5 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-700 
                        focus:outline-none focus:ring-2 focus:ring-signature focus:ring-offset-2 transition duration-300"
              >
                <FilterIcon className="h-4 w-4 text-white" aria-hidden="true" />
                <span className="sr-only">Search</span>
              </button>
            </form>
          </div>
          {/* Directory list */}
          <SearchLogic
            loading={!certsDirectory.length}
            dataCheck={certsDirectory.length}
            dataComponent={
              <nav className="min-h-0 flex-1 overflow-y-auto flex-grow" aria-label="Directory">
                {certsDirectory.map((el, index) => {
                  return (
                    <div key={index} className="relative">
                      <div className="sticky top-0 z-10 border-t border-b border-gray-200 px-6 py-1 text-sm font-medium text-gray-500 bg-gray-200">
                        <h3>{el.letter}</h3>
                      </div>
                      <ul className="relative z-0 divide-y divide-gray-200">
                        {certsDirectory[index].data.map((cert, index) => {
                          return (
                            <li key={index}>
                              <div
                                className={`relative flex items-center space-x-3 px-6 py-5 hover:bg-gray-200 transition duration-300
                                  ${
                                    selectedCert?.name === cert?.name &&
                                    'ring-inset ring-signature bg-gray-200 ring-2'
                                  }`}
                              >
                                <div className="flex-shrink-0">
                                  <img
                                    className="h-10 w-10 rounded-full"
                                    referrerPolicy="no-referrer"
                                    src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${cert?.logo}`}
                                    alt=""
                                  />
                                </div>
                                <div className="min-w-0 flex-1">
                                  <div className="focus:outline-none cursor-pointer">
                                    {/* Extend touch target to entire panel */}
                                    <span
                                      className="absolute inset-0"
                                      aria-hidden="true"
                                      onClick={() => {
                                        handleSelectCert(cert);
                                      }}
                                    />
                                    <p className="text-xs font-medium text-gray-900">
                                      {cert?.name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </nav>
            }
            searchInput={searchInput}
          />
        </aside>
        {selectedCert ? (
          <>
            <aside className="border-r border-gray-200 xl:order-first xl:flex xl:flex-col hidden xl:w-full">
              {selectedCert ? <SnapshotCert certDetails={selectedCert} editable /> : null}
            </aside>
          </>
        ) : (
          <LoadingState />
        )}
      </div>

      <SlideOver
        show={show}
        onClose={handleCloseSlideOver}
        onClickButtonClose={handleCloseSlideOver}
        className="xl:hidden"
        largeSize
        content={<SnapshotCert certDetails={target} editable />}
      />
    </div>
  );
};

export default CertsDirectoryAdminView;
