/* eslint-disable */
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Button, Input, TextArea } from '../../../custom-prebuilt/common.component';
import AddRequirementModal from './AddRequirementModal';
import Modal from '../../../custom-prebuilt/Modal.component';
import { CreateKnowledge, GetAllKnowledge } from './graphql/knowledge';
import {
  AttachRequiredCategories,
  AttachRequiredCerts,
  AttachRequiredPrompts,
  AttachRequiredSkills,
} from './graphql/requirements';
import ReactTooltip from 'react-tooltip';
import {
  ClipboardCheckIcon,
  ClipboardListIcon,
  DocumentIcon,
  PencilAltIcon,
  TrashIcon,
} from '@heroicons/react/outline';

const iconClass =
  'text-gray-400 group-hover:text-gray-500 mr-[6px] flex-shrink-0 h-5 w-5 transition duration-300';
const skillIcons = {
  Skill: <ClipboardCheckIcon className={iconClass} />,
  'Skill Category': <ClipboardListIcon className={iconClass} />,
  Certification: <DocumentIcon className={iconClass} />,
  Prompt: <PencilAltIcon className={iconClass} />,
};

const CreateNewKnowledge = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [open, setOpen] = useState(false);
  const [requirements, setRequirements] = useState([]);
  const [showInputErrors, setShowInputErrors] = useState(false);
  const { data } = useQuery(GetAllKnowledge, {
    variables: {
      options: {
        sort: [
          {
            name: 'ASC',
          },
        ],
      },
    },
  });
  const [createKnowledge] = useMutation(CreateKnowledge, {
    refetchQueries: ['GetAllKnowledge'],
  });
  const [attachRequiredSkills] = useMutation(AttachRequiredSkills);
  const [attachRequiredCategories] = useMutation(AttachRequiredCategories);
  const [attachRequiredCerts] = useMutation(AttachRequiredCerts);
  const [attachRequiredPrompts] = useMutation(AttachRequiredPrompts);

  const addRequirement = (reqType, req) => {
    console.log({ reqType, ...req });
    setRequirements([...requirements, { reqType, ...req }]);
  };

  const handleCreateKnowledge = async () => {
    if (!name || !description || !requirements.length) {
      setShowInputErrors(true);
      return;
    }
    setShowInputErrors(false);

    // const knowledgeId = uuidv4();
    const requiredSkills = requirements
      .filter((req) => req.reqType === 'Skill')
      .map((req) => ({
        skillId: req.id,
        minRating: req.minRating,
        minProjects: req.minProjects,
      }));
    const requiredCategories = requirements
      .filter((req) => req.reqType === 'Skill Category')
      .map((req) => ({
        categoryId: req.id,
        minSkills: req.minSkills,
        minRating: req.minRating,
        minProjects: req.minProjects,
      }));
    const requiredCerts = requirements
      .filter((req) => req.reqType === 'Certification')
      .map((req) => ({ certId: req.id }));
    const requiredPrompts = requirements
      .filter((req) => req.reqType === 'Prompt')
      .map((req) => ({
        question: req.name,
        type: req.type,
        choices: req.choices,
        correctAnswer: req.correctAnswer,
      }));
    let knowledgeId;
    await createKnowledge({
      variables: {
        input: [
          {
            name: name,
            // id: knowledgeId,
            description: description,
          },
        ],
      },
    })
      .then((resp) => {
        knowledgeId = resp.data.createKnowledges.knowledges?.[0]?.id;

        return attachRequiredSkills({
          variables: {
            knowledgeId,
            requiredSkills,
          },
        });
      })
      .then(() =>
        attachRequiredCategories({
          variables: {
            knowledgeId,
            requiredCategories,
          },
        }),
      )
      .then(() =>
        attachRequiredCerts({
          variables: {
            knowledgeId,
            requiredCerts,
          },
        }),
      )
      .then(() =>
        attachRequiredPrompts({
          variables: {
            knowledgeId,
            requiredPrompts,
          },
        }),
      )
      .then(() => {
        setName('');
        setDescription('');
        setRequirements([]);
      })
      .catch(console.error);
  };

  return (
    <div className="block lg:flex lg:flex-grow" style={{ position: 'relative' }}>
      <aside className="max-w-full 2xl:max-w-sm min-w-[20rem] 2xl:min-w-[30rem] flex-1 flex-grow border-r border-gray-200 xl:order-first lg:flex lg:flex-col">
        <div className="lg:px-6 pb-4">
          <h2 className="text-sm font-medium text-gray-900 mb-2 ml-1">Create New Knowledge Node</h2>
          <div className="w-full lg:max-w-lg p-6 bg-white shadow rounded-lg">
            <div className="flex flex-col space-y-2">
              <div className="flex flex-col space-y-1">
                <label className="text-xs">Name *</label>
                <Input
                  className={`border px-2 ${showInputErrors && !name ? 'border-red-500' : ''}`}
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="flex flex-col space-y-1">
                <label className="text-xs">Description *</label>
                <TextArea
                  className={`border px-2 resize-y h-24 ${
                    showInputErrors && !description ? 'border-red-500' : ''
                  }`}
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>

              <div className="flex flex-col space-y-1">
                <label className="text-xs">Requirements *</label>
              </div>
              <button
                onClick={() => setOpen(true)}
                className="rounded-md p-1 text-sm text-white bg-slate-800 hover:bg-slate-700"
              >
                Add Requirement
              </button>
              <div
                className={`flex flex-col items-stretch rounded-md p-2 space-y-1  border mt-2 ${
                  showInputErrors && !requirements.length ? 'border-red-500' : ''
                }`}
              >
                {!requirements.length ? (
                  <div className="text-sm">No Knowledge Requirements</div>
                ) : (
                  requirements.map((req, i) => (
                    <div
                      key={i}
                      className="bg-gray-200 text-gray-900 group px-2 py-1 text-xs font-medium rounded-md transition duration-300 cursor-pointer whitespace-nowrap"
                    >
                      <div className="flex justify-between items-center mb-1">
                        <div className="flex align-center">
                          {skillIcons[req.reqType]} {req.name}
                        </div>
                        <Button
                          onClick={() => {
                            let newReqs = [...requirements];
                            newReqs.splice(i, 1);
                            setRequirements(newReqs);
                          }}
                          className="bg-red-600 hover:bg-red-700"
                        >
                          <TrashIcon className="h-4 w-4" />
                        </Button>
                      </div>
                      <div>
                        {Object.keys(req).map((key, id) =>
                          key !== 'id' && key !== 'name' ? (
                            <div key={id}>
                              {key} - {JSON.stringify(req[key])}
                            </div>
                          ) : (
                            <></>
                          ),
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
              <div className="w-full justify-end flex flex-row space-x-1 mt-4">
                <Button onClick={() => handleCreateKnowledge()}>Create</Button>
              </div>
            </div>
          </div>
        </div>
      </aside>
      <aside className="border-r border-gray-200 xl:order-first xl:flex xl:flex-col xl:w-full">
        {/* <div className="flex flex-row border border-black rounded-md w-full h-full"> */}
        <div className="flex flex-col p-4 h-full">
          <h2 className="text-sm font-medium text-gray-900 mb-2 ml-1">All Knowledge Nodes</h2>
          <div className="flex flex-col space-y-2 mt-2 w-full">
            {!data ? (
              <span>Loading...</span>
            ) : (
              data.knowledges.map((k, i) => {
                return (
                  <div key={i}>
                    <ReactTooltip
                      id={`${i}-tip`}
                      place="left"
                      effect="solid"
                      backgroundColor="#1F2937"
                    >
                      <div>{k.description}</div>
                      {Object.keys(k)
                        .filter(
                          (key) =>
                            key !== 'name' &&
                            key !== 'id' &&
                            key !== 'description' &&
                            key != '__typename',
                        )
                        .map((key, idx) => (
                          <div key={idx}>
                            {key}: {JSON.stringify(k[key])}
                          </div>
                        ))}
                    </ReactTooltip>
                    <div
                      data-tip
                      data-for={`${i}-tip`}
                      className="bg-gray-200 text-gray-900 group flex items-center px-2 py-2 text-xs font-medium rounded-md transition duration-300 cursor-pointer whitespace-nowrap"
                      key={i}
                    >
                      {k.name}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        {/* </div> */}
        <Modal
          title="Add a Requirement"
          open={open}
          onSecondaryButtonClick={() => setOpen(false)}
          content={
            <AddRequirementModal
              closeModal={() => setOpen(false)}
              addRequirement={addRequirement}
            />
          }
        />
      </aside>
    </div>
  );
};

export default CreateNewKnowledge;
