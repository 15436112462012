import { PaperClipIcon } from '@heroicons/react/solid';
import GridTitle from '../shared/GridTitle.component';

const CurrentPlan = () => {
  return (
    <div className="h-fit w-full">
      <GridTitle>Your current plan</GridTitle>
      <div className="h-full w-full flex flex-col xl:flex-row space-y-4 xl:space-y-0 xl:space-x-5">
        <div className="min-w-[30%] aspect-1 rounded-lg bg-white shadow px-4 py-5 sm:px-6">
          <p className="font-medium text-sm">Snapshot</p>
        </div>

        <div className="flex-grow flex">
          <div className="overflow-hidden bg-white shadow rounded-lg flex-1">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-sm font-medium leading-6 text-gray-900">Details</h3>
              <p className="mt-1 max-w-2xl text-xs text-gray-500">Plan details and perks</p>
            </div>
            <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-xs font-medium text-gray-500">Remaining invites</dt>
                  <dd className="mt-1 text-xs text-gray-900 sm:col-span-2 sm:mt-0">Unlimited</dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-xs font-medium text-gray-500">Remaining organization</dt>
                  <dd className="mt-1 text-xs text-gray-900 sm:col-span-2 sm:mt-0">Unlimited</dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-xs font-medium text-gray-500">Budget limit</dt>
                  <dd className="mt-1 text-xs text-gray-900 sm:col-span-2 sm:mt-0">$12,000</dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-xs font-medium text-gray-500">Total invitees</dt>
                  <dd className="mt-1 text-xs text-gray-900 sm:col-span-2 sm:mt-0">1245</dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-xs font-medium text-gray-500">About</dt>
                  <dd className="mt-1 text-xs text-gray-900 sm:col-span-2 sm:mt-0">
                    Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum
                    culpa consequat. Excepteur qui ipsum aliquip consequat sint. Sit id mollit nulla
                    mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad
                    adipisicing reprehenderit deserunt qui eu.
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                  <dt className="text-xs font-medium text-gray-500">Attachments</dt>
                  <dd className="mt-1 text-xs text-gray-900 sm:col-span-2 sm:mt-0">
                    <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                      <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                        <div className="flex w-0 flex-1 items-center">
                          <PaperClipIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="ml-2 w-0 flex-1 truncate">
                            resume_back_end_developer.pdf
                          </span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <p className="font-medium text-signature cursor-pointer">Download</p>
                        </div>
                      </li>
                      <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                        <div className="flex w-0 flex-1 items-center">
                          <PaperClipIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="ml-2 w-0 flex-1 truncate">
                            coverletter_back_end_developer.pdf
                          </span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <p className="font-medium text-signature cursor-pointer">Download</p>
                        </div>
                      </li>
                    </ul>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentPlan;
