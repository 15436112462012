import MainProfileCard from '../MainProfileCard.component';

const UserOverview = ({ personDetails }) => {
  return (
    <div className="w-full h-fit bg-white rounded-md overflow-clip shadow">
      <MainProfileCard personDetails={personDetails} />
    </div>
  );
};

export default UserOverview;
