/* eslint-disable */

import PageContainer from '../../custom-prebuilt/PageContainer';
import Card from '../shared/Card.component';
import SkillExplorePrompt from './utils/SkillExplorePrompt.component';
import SkillExploreRes from './utils/SkillExploreRes.component';
import SkillExploreCanvas from './utils/SkillExploreCanvas.component';
import SkillExploreHistory from './utils/SkillExploreHistory.component';
import SimpleSection from '../shared/SimpleSection.component';
import { Button } from '../../custom-prebuilt/common.component';
import { useSelector, useDispatch } from 'react-redux';
import { toggleSlideOver } from '../../redux/slideOverSlice';
import { DragDropContext } from '@hello-pangea/dnd';
import { toggleExplore } from '../../redux/exploreSlice.js';
import { useState, useEffect } from 'react';
import { StopOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useLazyQuery } from '@apollo/client';
import { QueryPersonBasics } from '../../graphql/people';
import { successToastMid } from '../../lib/toast';
import LoadingState from '../../custom-prebuilt/preloader/LoadingState.component';

const STICKY_CANVAS_STYLE = 'xl:sticky xl:top-[calc(5.25rem+80px)]';

const CANVAS_COLORS = [
  'bg-red-400',
  'bg-green-400',
  'bg-purple-400',
  'bg-yellow-400',
  'bg-sky-400',
];

const SkillExplore = () => {
  const dispatch = useDispatch();
  const canvasItems = useSelector((state) => state.explore.canvasItems);
  const canvasColors = useSelector((state) => state.explore.canvasColors);
  const queryHistory = useSelector((state) => state.explore.queryHistory);
  const [canvases, setCanvases] = useState(1);
  const [fetchUser] = useLazyQuery(QueryPersonBasics);
  const [loading, setLoading] = useState(false);

  const processShareableURL = async (url) => {
    // Parse query-string
    const parsed = queryString.parse(url);
    const parsedKeys = Object.keys(parsed);
    let i = 0;
    const cvsItems = [];
    const cvsColors = [];
    const history = JSON.parse(atob(decodeURIComponent(parsed['history'])));

    while (i < (parsedKeys.length - 1) / 3) {
      // WATCH THIS
      const canvas = {
        data: JSON.parse(atob(decodeURIComponent(parsed[`canvas${i}`]))),
        title: parsed[`title${i}`],
        color: parsed[`color${i}`],
      };
      const cvsItem = [canvas.title, []];
      for (const email of canvas.data) {
        const user = await fetchUser({
          variables: { where: { email } },
          fetchPolicy: 'network-only',
        });
        user.data.people && cvsItem[1].push(user.data.people[0]);
      }

      cvsColors.push(canvas.color);
      cvsItems.push(cvsItem);
      i++;
    }
    // console.log(cvsItems)
    // console.log(cvsColors)

    dispatch(
      toggleExplore({
        canvasItems: cvsItems,
        canvasColors: cvsColors,
        queryHistory: history,
      }),
    );

    setLoading(false);
  };

  useEffect(() => {
    if (location.search !== '') {
      processShareableURL(location.search);
      setLoading(true);
    }
  }, []);

  useEffect(() => {
    dispatch(toggleExplore({ canvasColors: Array(canvasItems.length).fill('bg-white') }));
  }, [canvasItems.length]);

  const handleViewHistory = () => {
    dispatch(toggleSlideOver({ show2: true }));
  };

  useEffect(() => {
    setCanvases(canvasItems?.length);
  }, [canvasItems?.length]);

  const handleNewCanvas = () => {
    const temp = [...canvasItems];
    temp.push(['Canvas', []]);
    dispatch(
      toggleExplore({
        canvasItems: temp,
      }),
    );
  };

  const handleRemoveCanvas = (index) => {};

  const onDragStart = () => {};

  const onDragUpdate = () => {};

  const onDragEnd = (result) => {
    console.log(result);
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    const sourceId = source.droppableId;
    const destinationId = destination.droppableId;
    const sourceIndex = source.index;
    const destinationIndex = destination?.index;
    const sourceName = canvasItems[sourceId][0];
    const destinationName = canvasItems[destinationId][0];

    if (destinationId === sourceId && destinationIndex === sourceIndex) {
      return;
    }

    let newSource = Array.from(canvasItems[sourceId][1]);
    let newDestination = Array.from(canvasItems[destinationId][1]);
    const target = newSource[sourceIndex];
    if (sourceId === destinationId) {
      newSource.splice(sourceIndex, 1);
      newSource.splice(destinationIndex, 0, target);
      newDestination = newSource;
    } else {
      newSource.splice(sourceIndex, 1);
      newDestination.splice(destinationIndex, 0, target);
    }

    const res = [...canvasItems];
    res.splice(source.droppableId, 1, [sourceName, newSource]);
    res.splice(destination.droppableId, 1, [destinationName, newDestination]);

    dispatch(toggleExplore({ canvasItems: res }));
  };

  const handleSetCanvasColor = (color, canvasIdx) => {
    let newColor = color;
    newColor = newColor.split('-');
    newColor.splice(2, 1, '100');
    newColor = newColor.join('-');
    const newCanvasColors = [...canvasColors];
    newCanvasColors.splice(canvasIdx, 1, newColor);
    dispatch(toggleExplore({ canvasColors: newCanvasColors }));
  };

  const renderCanvasColor = (color) => {
    switch (color) {
      case 'bg-green-100':
        return 'bg-green-100';
      case 'bg-red-100':
        return 'bg-red-100';
      case 'bg-yellow-100':
        return 'bg-yellow-100';
      case 'bg-sky-100':
        return 'bg-sky-100';
      case 'bg-purple-100':
        return 'bg-purple-100';
      default:
        return 'bg-white';
    }
  };

  const handleShare = (e) => {
    e.preventDefault();
    /**
     * Guarantee? canvasItems.length = canvasColors.length
     */
    const canvasCount = canvasItems.length;
    let shareableURL = '?';
    for (let canvasIndex = 0; canvasIndex < canvasCount; canvasIndex++) {
      if (canvasIndex > 0) {
        shareableURL += '&';
      }
      const canvasItem = [...canvasItems[canvasIndex]];

      /**
       * For code running using Node.js APIs, converting between base64-encoded strings and binary data
       * should be performed using Buffer.from(str, 'base64') andbuf.toString('base64').
       */
      const canvas = {
        data: encodeURIComponent(btoa(JSON.stringify(canvasItem[1].map((person) => person.email)))),
        title: encodeURIComponent(canvasItem[0]),
        color: canvasColors[canvasIndex],
      };
      shareableURL += `canvas${canvasIndex}=${canvas.data}&title${canvasIndex}=${canvas.title}&color${canvasIndex}=${canvas.color}`;
    }
    const history = encodeURIComponent(btoa(JSON.stringify(queryHistory)));
    shareableURL += `&history=${history}`;
    // console.log(shareableURL)
    if (shareableURL.length > 2000) {
      throw new Error('This Explore canvas is too complex to share.');
    }
    // Copy to clipboard
    navigator.clipboard.writeText(`${location.origin}${location.pathname}${shareableURL}`);
    successToastMid('Shareable URL copied to clipboard.');
  };

  return (
    <>
      {loading ? (
        <LoadingState />
      ) : (
        <PageContainer
          className="page-container-no-padding-bottom"
          title={'Explore'}
          actionName="New canvas"
          action={handleNewCanvas}
        >
          <div className="xl:grid xl:grid-cols-[30%_70%] xl:space-x-4 space-y-0">
            <div
              className={`flex flex-col space-y-4 h-fit xl:h-full-init w-full z-[55] ${STICKY_CANVAS_STYLE}`}
            >
              <SimpleSection className="h-fit">
                <div className="flex flex-row items-center justify-between">
                  <p className="text-xs font-semibold leading-6">Prompt</p>
                  <div>
                    <Button onClick={handleShare}>Share</Button>
                    <Button onClick={handleViewHistory}>History</Button>
                  </div>
                </div>
                <SkillExplorePrompt />
              </SimpleSection>
              <Card className="flex-1 overflow-y-auto" contentClassName="flex-1" title={'Results'}>
                <SkillExploreRes />
              </Card>
            </div>

            <div className="h-fit xl:grid xl:grid-cols-1 gap-4">
              <DragDropContext
                onDragStart={onDragStart}
                onDragUpdate={onDragUpdate}
                onDragEnd={onDragEnd}
              >
                {new Array(canvases).fill(0).map((e, index) => (
                  <SimpleSection
                    key={index}
                    // className="h-fit xl:h-full-init xl:overflow-y-auto overflow-x-hidden max-h-full col-span-1">
                    className={`h-fit col-span-1 transition duration-300 ${renderCanvasColor(
                      canvasColors[index],
                    )}`}
                  >
                    <div className="flex flex-row space-x-1 mb-2">
                      <StopOutlined
                        onClick={() => {
                          handleSetCanvasColor('bg-white', index);
                        }}
                        className="h-4 w-4 cursor-pointer rounded-full"
                      />
                      {CANVAS_COLORS.map((color, idx) => (
                        <div
                          key={idx}
                          className={`${color} rounded-full h-4 w-4 cursor-pointer`}
                          onClick={() => {
                            handleSetCanvasColor(color, index);
                          }}
                        ></div>
                      ))}
                    </div>
                    <SkillExploreCanvas canvasIdx={index} />
                  </SimpleSection>
                ))}
              </DragDropContext>
            </div>
          </div>

          <SkillExploreHistory />
        </PageContainer>
      )}
    </>
  );
};

export default SkillExplore;
