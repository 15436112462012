import PageContainer from '../../custom-prebuilt/PageContainer';
import TextEditor from './text-editor/TextEditor.component';

const CreateAnnouncement = () => {
  return (
    <PageContainer title={'Create announcement'}>
      <TextEditor />
    </PageContainer>
  );
};

export default CreateAnnouncement;
