import { useQuery } from '@apollo/client';
import { H2 } from '../../custom-prebuilt/common.component';
import LoadingState from '../../custom-prebuilt/preloader/LoadingState.component';
import { QueryUserEducation } from '../../graphql/educations';
import { useAuth } from '../../lib/authContext';
import AddEducation from './AddEducation.component';
import EditableEducationCard from './EditableEducationCard.component';

/** Settings section for editing a user's past educational experience
 * @returns previously added educations and a section to add a new education
 */
const SettingsEducation = () => {
  const { userEmail } = useAuth();
  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery(QueryUserEducation, {
    variables: {
      where: {
        email: userEmail,
      },
    },
    skip: !userEmail,
  });

  if (error) {
    console.error(error);
  }

  return (
    <>
      {queryLoading ? (
        <LoadingState />
      ) : (
        <div className="px-4 w-full h-full overflow-y-auto">
          <H2>Education</H2>
          <div className="lg:w-5/12 space-y-4 my-6">
            {!data?.people[0].attendedConnection.edges.length ? (
              <span className="text-font-light">No Education</span>
            ) : (
              data?.people[0].attendedConnection.edges.map((university) => {
                return <EditableEducationCard key={university.id} attendedData={university} />;
              })
            )}
          </div>
          <div className="lg:w-1/2">
            <AddEducation />
          </div>
        </div>
      )}
    </>
  );
};
export default SettingsEducation;
