import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { api_getConnectorLogs } from '../people/utils/fetchResp';
import LoadingState from '../../../custom-prebuilt/preloader/LoadingState.component';
import Accordion from '../../../custom-prebuilt/Accordion.component';
import { Button } from '../../../custom-prebuilt/common.component';

const ConnectorLogs = ({ connectorId }) => {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const [nextToken, setNextToken] = useState('NO_TOKEN');

  useEffect(() => {
    setLoading(true);
    Auth.currentAuthenticatedUser().then((cogUser) => {
      cogUser.getSession((err, session) => {
        if (err) {
          throw new Error(err);
        }
        const sessionToken = session?.getAccessToken().getJwtToken();
        api_getConnectorLogs(sessionToken, connectorId).then((res) => {
          const connectorLogs = [];
          for (const time of Object.keys(res.output)) {
            let log = {
              logStream: new Date(parseInt(time)),
              bundle: res.output[time],
            };
            connectorLogs.push(log);
          }
          setLogs(connectorLogs);
          setNextToken(res.nextToken);
          setLoading(false);
        });
      });
    });
  }, [connectorId]);

  const handleFetchMore = async () => {
    Auth.currentAuthenticatedUser().then((cogUser) => {
      cogUser.getSession((err, session) => {
        if (err) {
          throw new Error(err);
        }
        const sessionToken = session?.getAccessToken().getJwtToken();
        api_getConnectorLogs(sessionToken, connectorId, nextToken).then((res) => {
          const connectorLogs = [...logs];
          for (const time of Object.keys(res.output)) {
            let log = {
              logStream: new Date(parseInt(time)),
              bundle: res.output[time],
            };
            connectorLogs.push(log);
          }
          setLogs(connectorLogs);
          setNextToken(res.nextToken);
          setLoading(false);
        });
      });
    });
  };

  const formatLog = (log) => {
    const delimiter = log.indexOf(':');
    return log.substring(delimiter + 1);
  };

  return (
    <div className="mb-4 flex flex-col space-y-4">
      {loading ? (
        <LoadingState />
      ) : !logs.length ? (
        <div>No Logs</div>
      ) : (
        <div>
          Total: {logs.length}
          {logs.map((log, i) => (
            <Accordion
              key={i}
              title={`Log Stream ${log.logStream}`}
              content={
                <div className="w-full flex flex-col">
                  <Accordion
                    title={`Authentication Logs`}
                    content={
                      <div className="w-full text-xs font-normal whitespace-pre-wrap break-words pr-2">
                        {log.bundle['auth'].map((a, aKey) => (
                          <div key={aKey} className="w-full my-5 mt-0">
                            {formatLog(a)}
                          </div>
                        ))}
                      </div>
                    }
                  />
                  <Accordion
                    title={`Query Logs`}
                    content={
                      <div className="w-full text-xs font-normal whitespace-pre-wrap break-words pr-2">
                        {log.bundle['query'].map((a, aKey) => (
                          <div key={aKey} className="w-full my-5 mt-0">
                            {formatLog(a)}
                          </div>
                        ))}
                      </div>
                    }
                  />
                  <Accordion
                    title={`Map Logs`}
                    content={
                      <div className="w-full text-xs font-normal whitespace-pre-wrap break-words pr-2">
                        {log.bundle['map'].map((a, aKey) => (
                          <div key={aKey} className="w-full my-5 mt-0">
                            {formatLog(a)}
                          </div>
                        ))}
                      </div>
                    }
                  />
                  <Accordion
                    title={`Link Logs`}
                    content={
                      <div className="w-full text-xs font-normal whitespace-pre-wrap break-words pr-2">
                        {log.bundle['link'].map((a, aKey) => (
                          <div key={aKey} className="w-full my-5 mt-0">
                            {formatLog(a)}
                          </div>
                        ))}
                      </div>
                    }
                  />
                </div>
              }
            />
          ))}
          {nextToken !== 'NO_TOKEN' && <Button onClick={handleFetchMore}>More</Button>}
        </div>
      )}
    </div>
  );
};

export default ConnectorLogs;
