import LoadingState from '../../custom-prebuilt/preloader/LoadingState.component';
import defaultImg from '../../assets/default-user.svg';

const MainProfileCard = ({ personDetails }) => {
  const education = personDetails?.attendedConnection?.edges?.map((s) => (
    <div key={s.id} className="mb-2">
      <div className="font-semibold">{s.node?.educationName}</div>
      <div>{s.degreeName}</div>
      <div>{(s.startDate || '?') + '—' + (s.endDate || '?')}</div>
    </div>
  ));
  return (
    <>
      {!personDetails ? (
        <LoadingState />
      ) : (
        <div className="flex flex-col relative w-full h-fit px-5 pb-5">
          <div className="h-[13rem] flex flex-col justify-end relative mb-8">
            <div className="w-[calc(100%+2.5rem)] -ml-[1.25rem] h-[120px] bg-gray-800 absolute top-0 left-0">
              {personDetails?.userBannerUrl && (
                <img
                  className="w-full h-[120px] object-cover"
                  src={personDetails?.userBannerUrl}
                  alt=""
                />
              )}
            </div>

            <div className="inset-x-9 flex space-x-5 items-end z-10">
              <div className="flex-shrink-0">
                <img
                  className="w-36 xl:w-40 2xl:w-44 rounded-full bg-white border-2"
                  src={personDetails?.userIconUrl ?? defaultImg}
                  alt=""
                  referrerPolicy="no-referrer"
                />
              </div>
              <div className="flex flex-col mt-12 space-y-1">
                <h2 className="text-base font-semibold">{personDetails.name}</h2>
                <span className="text-xs text-font-dark">
                  {personDetails?.orgUnitsConnection?.edges[0]?.position ?? 'N/A'}
                </span>
                <span className="text-xs text-font-light">
                  {personDetails?.orgUnitsConnection?.edges[0]?.node?.name ?? 'N/A'}
                </span>
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-3">
            <p className="text-xs font-semibold text-font-dark">Contacts</p>
            <div className="grid sm:grid-cols-2 gap-4 mb-8 w-full">
              <div className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="fill-font-dark w-5"
                >
                  <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                  <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                </svg>
                <span className="text-xs text-font-dark">{personDetails?.email ?? 'N/A'}</span>
              </div>

              <div className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 17.032 17.032"
                  className="w-5 fill-font-dark"
                >
                  <path d="M3.578,10.762A1.789,1.789,0,1,1,1.79,8.974H3.578v1.788Zm.9,0a1.789,1.789,0,0,1,3.578,0v4.48a1.789,1.789,0,1,1-3.578,0v-4.48ZM6.269,3.578A1.789,1.789,0,1,1,8.058,1.79V3.578H6.269Zm0,.9a1.789,1.789,0,1,1,0,3.578H1.79a1.789,1.789,0,1,1,0-3.578H6.269Zm7.183,1.789a1.79,1.79,0,1,1,1.79,1.789h-1.79Zm-.9,0a1.789,1.789,0,0,1-3.579,0V1.79a1.789,1.789,0,1,1,3.579,0Zm-1.79,7.183a1.79,1.79,0,1,1-1.788,1.79v-1.79Zm0-.9a1.789,1.789,0,0,1,0-3.579h4.48a1.789,1.789,0,1,1,0,3.579Z" />
                </svg>
                <span className="text-xs text-font-dark">
                  {personDetails?.slackUsername ?? 'N/A'}
                </span>
              </div>

              <div className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="fill-font-dark w-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="text-xs text-font-dark">{personDetails?.phone ?? 'N/A'}</span>
              </div>

              <div className="flex items-center space-x-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="w-5 fill-font-dark"
                >
                  <path d="M4 20q-.825 0-1.412-.587Q2 18.825 2 18V6q0-.825.588-1.412Q3.175 4 4 4h16q.825 0 1.413.588Q22 5.175 22 6v12q0 .825-.587 1.413Q20.825 20 20 20Zm0-2h10.5v-3.5H4V18Zm12.5 0H20V9h-3.5ZM4 12.5h10.5V9H4Z" />
                </svg>
                <span className="text-xs text-font-dark">
                  {personDetails?.personalWebsite ?? 'N/A'}
                </span>
              </div>
            </div>
          </div>

          {personDetails?.biography ? (
            <div className="flex flex-col mt-8">
              <span className="text-xs text-font-dark font-semibold mb-3">Biography</span>
              <p className="text-xs text-font-dark w-full line-clamp-[10]">
                {personDetails?.biography}
              </p>
            </div>
          ) : null}

          {education?.length ? (
            <div className="flex flex-col mt-8">
              <span className="text-xs text-font-dark font-semibold mb-3">Education</span>
              <div className="text-xs text-font-dark w-full line-clamp-[10]">{education}</div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default MainProfileCard;
