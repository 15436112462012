import { useMutation } from '@apollo/client';
import { useState } from 'react';
import {
  Button,
  H2,
  Input,
  InputErrorCheck,
  Label,
  Legend,
  Select,
} from '../../custom-prebuilt/common.component';
import LoadingState from '../../custom-prebuilt/preloader/LoadingState.component';
import { ConnectUserToEducation } from '../../graphql/educations';
import educationList from '../../assets/education.json';
import SearchAndSelectInput from '../../custom-prebuilt/SearchAndSelectInput';
import { useAuth } from '../../lib/authContext';

export const degreeData = [
  { degree: 'Associate of Arts (AA)' },
  { degree: 'Associate of Science (AS)' },
  { degree: 'Associate of Applied Science (AAS)' },
  { degree: 'Bachelor of Arts (BA)' },
  { degree: 'Bachelor of Science (BS)' },
  { degree: 'Bachelor of Applied Science (BAS)' },
  { degree: 'Master of Arts (MA)' },
  { degree: 'Master of Science (MS)' },
  { degree: 'Master of Business Administration (MBA)' },
  { degree: 'Doctoral (PhD)' },
];

/**Section for adding a user's education experience
 * @returns a form for inputing new education experience
 */
const AddEducation = () => {
  const { userEmail } = useAuth();
  const [inputError, setError] = useState();

  const [addEducation, { loading: updateLoading, error: updateError }] = useMutation(
    ConnectUserToEducation,
    {
      refetchQueries: ['QueryUserEducation'],
      ignoreResults: true,
    },
  );

  //input state variables
  const [search, setSearch] = useState('');
  const [university, setUniversity] = useState('');
  const [degree, setDegree] = useState('');
  const [major, setMajor] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const onAdd = () => {
    if (
      university === '' ||
      degree === '' ||
      startDate === '' ||
      endDate === '' ||
      !Number(startDate) ||
      !Number(endDate)
    ) {
      setError(true);
      return;
    }

    addEducation({
      variables: {
        input: {
          email: userEmail,
          educationName: university,
          degreeName: degree,
          majorName: major,
          startDate: startDate,
          endDate: endDate,
        },
      },
    });
    setUniversity('');
    setSearch('');
    setDegree('');
    setMajor('');
    setStartDate('');
    setEndDate('');
    setError();
  };

  if (updateError) {
    console.error(updateError);
  }

  return updateLoading ? (
    <LoadingState />
  ) : (
    <div className="flex flex-col w-full">
      <H2 className="mb-2">Add New Education</H2>

      <Label>
        Select the college or university that you attended
        <SearchAndSelectInput
          classes="my-2 w-full"
          search={search}
          setSearch={(val) => {
            setSearch(val);
          }}
          data={educationList}
          searchField={'educationName'}
          setSelected={(val) => {
            setUniversity(val);
          }}
        />
      </Label>
      <InputErrorCheck value={university} error={inputError}>
        This is a required field
      </InputErrorCheck>
      <Legend>If your college/university is not present, contact your administrator.</Legend>

      <Label>
        Select the degree you received
        <Select value={degree} onChange={(e) => setDegree(e.target.value)}>
          <option value={''} />
          {degreeData.map((degree, index) => {
            return (
              <option key={index} value={degree.degree}>
                {degree.degree}
              </option>
            );
          })}
        </Select>
      </Label>
      <InputErrorCheck value={degree} error={inputError}>
        This is a required field
      </InputErrorCheck>
      <Legend>
        If your degree is not present, contact your administrator.
        <br />
        If you received multiple degrees at this college/university, create another entry.
      </Legend>

      <Label>
        Enter the field you majored in (optional)
        <Input type="text" value={major} onChange={(e) => setMajor(e.target.value)} />
      </Label>

      <div className="flex justify-between">
        <div>
          <Label>
            What year did you start attending?
            <Input
              type="text"
              maxLength="4"
              minLength="4"
              inputMode="numeric"
              pattern="[0-9]{4}"
              value={startDate}
              onChange={(e) => {
                if (Number(e.target.value) || e.target.value === '') setStartDate(e.target.value);
              }}
            />
          </Label>
          <InputErrorCheck value={startDate} error={inputError}>
            This is a required field
          </InputErrorCheck>
          <InputErrorCheck value="" error={inputError && startDate && !Number(startDate)}>
            This must contain a year.
          </InputErrorCheck>
        </div>
        <div>
          <Label>
            What year did/will you stop attending?
            <Input
              type="text"
              maxLength="4"
              minLength="4"
              inputMode="numeric"
              pattern="[0-9]{4}"
              value={endDate}
              onChange={(e) => {
                if (Number(e.target.value) || e.target.value === '') setEndDate(e.target.value);
              }}
            />
          </Label>
          <InputErrorCheck value={endDate} error={inputError && startDate !== ''}>
            This is a required field
          </InputErrorCheck>
          <InputErrorCheck value="" error={inputError && endDate && !Number(endDate)}>
            This must contain a year.
          </InputErrorCheck>
        </div>
      </div>

      <Button className="mt-14 self-end" onClick={onAdd}>
        Add Education
      </Button>
    </div>
  );
};
export default AddEducation;
