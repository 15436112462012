import AssessmentListCard from './AssessmentListCard.component';
import GridTitle from '../shared/GridTitle.component';

const AssessmentList = ({ assessments }) => {
  return (
    <div>
      <GridTitle>Available assessment</GridTitle>
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {assessments.map((a, index) => (
          <div key={index}>
            <AssessmentListCard assessment={a} />
          </div>
        ))}
      </ul>
    </div>
  );
};

export default AssessmentList;
