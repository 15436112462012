import { useCallback, useEffect, useMemo } from 'react';
import SkillPie from '../../../custom-prebuilt/SkillPie.component';
import Card from '../../shared/Card.component';
import ClickNHoldSkill from '../../../custom-prebuilt/ClickNHoldSkill.component';
import { useHorizontalTabs } from '../../../lib/customHook';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import ReactTooltip from 'react-tooltip';

const UserSkills = ({ userDetails }) => {
  const skillPieMemoizedData = useMemo(() => {
    return [
      {
        id: 'Languages',
        label: 'Languages',
        value: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Languages'
            ? acc + 1
            : acc;
        }, 0),
        skills: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Languages'
            ? [...acc, curr]
            : acc;
        }, []),
      },
      {
        id: 'Backend',
        label: 'Back End',
        value: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Back End'
            ? acc + 1
            : acc;
        }, 0),
        skills: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Back End'
            ? [...acc, curr]
            : acc;
        }, []),
      },
      {
        id: 'Frontend',
        label: 'Front End',
        value: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Front End'
            ? acc + 1
            : acc;
        }, 0),
        skills: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Front End'
            ? [...acc, curr]
            : acc;
        }, []),
      },
      {
        id: 'Mobile',
        label: 'Mobile',
        value: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Mobile' ? acc + 1 : acc;
        }, 0),
        skills: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Mobile'
            ? [...acc, curr]
            : acc;
        }, []),
      },
      {
        id: 'Web Platforms',
        label: 'Web Platforms',
        value: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Web Platforms'
            ? acc + 1
            : acc;
        }, 0),
        skills: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Web Platforms'
            ? [...acc, curr]
            : acc;
        }, []),
      },
      {
        id: 'Techniques',
        label: 'Techniques',
        value: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Techniques'
            ? acc + 1
            : acc;
        }, 0),
        skills: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Techniques'
            ? [...acc, curr]
            : acc;
        }, []),
      },
      {
        id: 'Testing',
        label: 'Testing',
        value: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Testing' ? acc + 1 : acc;
        }, 0),
        skills: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Testing'
            ? [...acc, curr]
            : acc;
        }, []),
      },
      {
        id: 'DevOps',
        label: 'DevOps',
        value: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'DevOps' ? acc + 1 : acc;
        }, 0),
        skills: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'DevOps'
            ? [...acc, curr]
            : acc;
        }, []),
      },
      {
        id: 'Data',
        label: 'Data',
        value: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Data' ? acc + 1 : acc;
        }, 0),
        skills: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Data'
            ? [...acc, curr]
            : acc;
        }, []),
      },
      {
        id: 'Design',
        label: 'Design',
        value: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Design' ? acc + 1 : acc;
        }, 0),
        skills: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Design'
            ? [...acc, curr]
            : acc;
        }, []),
      },
      {
        id: 'AWS',
        label: 'AWS',
        value: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'AWS' ? acc + 1 : acc;
        }, 0),
        skills: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'AWS'
            ? [...acc, curr]
            : acc;
        }, []),
      },
      {
        id: 'Azure',
        label: 'Azure',
        value: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Azure' ? acc + 1 : acc;
        }, 0),
        skills: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Azure'
            ? [...acc, curr]
            : acc;
        }, []),
      },
      {
        id: 'Google Cloud',
        label: 'Google Cloud',
        value: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Google Cloud'
            ? acc + 1
            : acc;
        }, 0),
        skills: userDetails?.skillsConnection?.edges.reduce((acc, curr) => {
          return curr?.node?.categoryConnection?.edges[0]?.node.value === 'Google Cloud'
            ? [...acc, curr]
            : acc;
        }, []),
      },
    ];
  }, [userDetails]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const knowledgable = userDetails?.skillsConnection?.edges?.filter((skill) => skill.rating === 1);
  const proficient = userDetails?.skillsConnection?.edges?.filter((skill) => skill.rating === 2);
  const leadTeach = userDetails?.skillsConnection?.edges?.filter((skill) => skill.rating === 3);

  const [skillTabParent] = useAutoAnimate({ duration: 150 });
  const tabs = ['Category', 'All Skills'];
  /* eslint-disable-next-line */
  const [current, setCurrent, tabComponent] = useHorizontalTabs(tabs);

  const skillLists = [
    { name: 'Knowledgable', skills: knowledgable },
    { name: 'Proficient', skills: proficient },
    { name: 'Lead/Teach', skills: leadTeach },
  ];

  const skillListComponent = useCallback(
    (skillList) => {
      return (
        <div>
          <p className="text-xs font-semibold">{skillList?.name}</p>
          <div className="flex flex-row flex-wrap mt-3">
            {skillList?.skills?.map((skill, idx) => (
              <div key={idx} className="w-12 lg:w-14" data-tip={skill.node.name} data-for="skill">
                <ClickNHoldSkill skill={skill?.node} disable />
              </div>
            ))}
          </div>
        </div>
      );
    },
    [skillLists],
  );

  const skillComponent = useMemo(() => {
    return (
      <Card
        title="Skills"
        titleCounter={userDetails?.skillsConnection?.edges.length}
        contentClassName="px-0"
        displayCounter
      >
        {tabComponent}
        <div ref={skillTabParent}>
          {current === 0 && (
            <div className="w-full aspect-1 mt-4">
              <SkillPie data={skillPieMemoizedData} />
            </div>
          )}
          {current === 1 && (
            <div className="mt-5 flex flex-col space-y-4">
              {skillLists.map((skillList, idx) => (
                <div key={idx}>{skillListComponent(skillList)}</div>
              ))}
            </div>
          )}
        </div>
      </Card>
    );
  }, [skillPieMemoizedData, userDetails?.skillsConnection?.edges.length, current]);

  return (
    <>
      {skillComponent}
      <div className="hidden xl:block">
        <ReactTooltip place="top" effect="solid" backgroundColor="#1F2937" id="skill" />
      </div>
    </>
  );
};

export default UserSkills;
