/* eslint-disable */
import { classNames } from '../lib/classNames';
import { Switch } from '@headlessui/react';
import ReactTooltip from 'react-tooltip';
import { LegacyRef, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { forwardRef } from 'react';
import SlideOver from './SlideOver.component';

const INPUT_BORDERS = 'rounded-md border-borders';
const DISABLED_STYLE = 'disabled:opacity-75';

/**
 * A styled input label
 * @param {React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>} props
 * @returns JSX.IntrinsicElements.label
 */
export const Label = ({
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>) => (
  <label className={classNames('block font-bold text-xs mt-4 text-font-dark', className)} {...rest}>
    {children}
  </label>
);

/**
 * A styled h2
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>} props
 * @returns JSX.IntrinsicElements.h2
 */
export const H2 = ({
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>) => (
  <h2 className={classNames('font-bold text-xl text-font-dark', className)} {...rest}>
    {children}
  </h2>
);

/**
 * A styled Input
 * @param {React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>} props
 * @returns JSX.IntrinsicElements.input
 */
export const Input = forwardRef(
  (
    {
      className,
      ...rest
    }: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    ref: LegacyRef<HTMLInputElement> | undefined,
  ) => (
    <input
      ref={ref}
      className={classNames(
        'block w-full font-normal h-8 focus:border-signature text-xs my-2 px-2 transition duration-150 shadow-sm border border-transparent',
        INPUT_BORDERS,
        DISABLED_STYLE,
        className,
      )}
      {...rest}
    />
  ),
);

/**
 * An input error check that displays only when error parameter is true and value is empty
 * @param {ReactNode | undefined, String} children content of the error message
 * @param {String} value value to check if empty
 * @param {boolean} error controls whether error is displayed
 * @returns an error message
 */
export type InputErrorCheckProps = {
  children?: ReactNode | undefined;
  value: string;
  error: boolean;
};
export const InputErrorCheck = ({ children, value, error }: InputErrorCheckProps) => {
  return value === '' && error ? (
    <p className="text-xs w-full mb-1 text-red-500 italic">{children}</p>
  ) : (
    <></>
  );
};

/**
 * A Styled TextArea
 * @param {React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>} props
 * @returns JSX.IntrinsicElements.textarea
 */
export const TextArea = ({
  className,
  ...rest
}: React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>) => (
  <textarea
    className={classNames(
      'block w-full font-normal focus:border-signature focus:ring-signature text-xs my-2 resize-none',
      INPUT_BORDERS,
      DISABLED_STYLE,
      className,
    )}
    {...rest}
  />
);

/**
 * A styled Paragraph, for descriptive text
 * @param {React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>} props
 * @returns JSX.IntrinsicElements.p
 */
export const Legend = ({
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>) => (
  <p className={classNames('text-font-light text-xs mb-2', className)} {...rest}>
    {children}
  </p>
);

/**
 * A Styled Button
 * @param {React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} props
 * @returns JSX.IntrinsicElements.button
 */
export const Button = ({
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => (
  <button
    className={classNames(
      'rounded-md border border-gray-300 bg-bg-dark px-3.5 py-2 text-xs font-medium text-white shadow-sm hover:bg-gray-700 transition duration-300',
      DISABLED_STYLE,
      className,
    )}
    {...rest}
  >
    {children}
  </button>
);

/**
 * A Styled Select
 * @param {React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>} props
 * @returns JSX.IntrinsicElements.select
 */
export const Select = ({
  children,
  className,
  ...rest
}: React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>) => (
  <select
    className={classNames(
      'h-8 my-2 text-xs w-full font-normal',
      INPUT_BORDERS,
      DISABLED_STYLE,
      className,
    )}
    {...rest}
  >
    {children}
  </select>
);

/**
 * A Styled Toggle Switch
 * @param {*} boolean A boolean to identify behavior
 */
type DefaultToggleProps = {
  checked?: boolean | undefined;
  defaultChecked?: boolean | undefined;
  onChange?: (e?: any) => void;
  name?: string | undefined;
  value?: string | undefined;
};
export type ToggleProps = {
  boolean: boolean;
  className?: string;
} & DefaultToggleProps;
export const Toggle = ({ boolean, className, ...rest }: ToggleProps) => (
  <Switch
    className={classNames(
      'group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-signature focus:ring-offset-2',
      className,
    )}
    {...rest}
  >
    <span className="sr-only">Use setting</span>
    <span
      aria-hidden="true"
      className="pointer-events-none absolute h-full w-full rounded-md bg-white"
    />
    <span
      aria-hidden="true"
      className={classNames(
        boolean ? 'bg-signature' : 'bg-gray-200',
        'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
      )}
    />
    <span
      aria-hidden="true"
      className={classNames(
        boolean ? 'translate-x-5' : 'translate-x-0',
        'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
      )}
    />
  </Switch>
);

/**
 *
 * @param {*} checked True to checked.
 * @returns
 */
export type CheckboxInputProps = {
  className?: string;
  children?: ReactNode | undefined;
  checked: boolean;
  name: string;
  onChange: (e?: any) => void;
};
export const CheckboxInput = ({
  className,
  children,
  checked,
  name,
  onChange,
}: CheckboxInputProps) => (
  <div className={classNames(`flex flex-row items-center space-x-2`, className)}>
    <input
      onChange={onChange}
      id={name}
      type="checkbox"
      className="focus:ring-0 focus:ring-offset-0 outline-none rounded-sm"
      checked={checked}
      name={name}
    />
    <label htmlFor={name} className="text-xs">
      {children}
    </label>
  </div>
);

/**
 * A styled NavBar
 * @param {React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLDivElement>, HTMLDivElement>} props
 * @returns JSX.IntrinsicElements.div
 */
export const NavBar = ({
  className,
  children,
  ...rest
}: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div className={classNames('w-full', className)} {...rest}>
    <div className="flex flex-row items-center">
      <nav className="flex" aria-label="Tabs">
        {children}
      </nav>
    </div>
  </div>
);

/**
 * A styled NavTab
 * @param {React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>} props
 * @returns JSX.IntrinsicElements.p
 */
export type NavTabProps = {
  label: string;
  active: boolean;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>;
export const NavTab = ({ label, active, onClick, className }: NavTabProps) => (
  <p
    key={label}
    className={classNames(
      active ? 'bg-gray-300 text-black' : 'text-gray-500 hover:text-gray-700',
      'px-3 py-2 font-medium text-xs rounded-md cursor-pointer transition duration-150',
      className,
    )}
    onClick={onClick}
  >
    {label}
  </p>
);

/**
 * A styled AvatarGroup
 */
type AvatarProps = {
  imageLink: string;
  name: string;
  link: string;
};
export type AvatarGroupProps = {
  label: string;
  avatars: AvatarProps[];
  limit: string | number;
  className?: string;
  labelTip?: string;
};
export const AvatarGroup = ({ label, avatars, limit, className, labelTip }: AvatarGroupProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  let Avatars;

  const handleViewAllAvatars = () => {
    setOpen(true);
  };

  const handleCloseSlideover = () => {
    setOpen(false);
  };

  if (avatars.length) {
    let avatarLen = avatars.length;
    let visibleAvatars = avatars.slice(0, Number(limit));
    Avatars = visibleAvatars.map((a) => {
      const imgURL = `${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${a.imageLink}`;

      return (
        <img
          className="w-10 h-10 rounded-full ring-2 ring-white hover:ring-signature transition duration-300 cursor-pointer bg-stone-50"
          src={imgURL}
          alt={a.name}
          data-tip={a.name}
          data-for={`${label}-members`}
          key={a.name}
          onClick={() => navigate(a.link)}
        />
      );
    });
    if (avatarLen > visibleAvatars.length) {
      Avatars.push(
        <div key="plus">
          <div
            className="cursor-pointer flex justify-center items-center w-10 h-10 text-xs font-medium text-white bg-gray-700 rounded-full border-2 border-white hover:bg-gray-600 dark:border-gray-800"
            onClick={handleViewAllAvatars}
          >
            +{avatarLen - visibleAvatars.length}
          </div>
          <SlideOver
            show={open}
            onClose={handleCloseSlideover}
            onClickButtonClose={handleCloseSlideover}
            title={label}
            content={
              <div className="w-full h-full flex flex-col bg-bg-light cursor-pointer">
                {avatars.map((a) => {
                  const imgURL = `${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${a.imageLink}`;
                  return (
                    <div
                      key={a.name}
                      onClick={() => navigate(a.link)}
                      className="flex items-center gap-5 p-2 hover:bg-gray-200"
                    >
                      <img
                        className="w-10 h-10 rounded-full ring-2 ring-white transition duration-300 cursor-pointer bg-stone-50"
                        src={imgURL}
                        alt={a.name}
                      />
                      <span className="text-sm font-medium">{a.name}</span>
                    </div>
                  );
                })}
                <div></div>
              </div>
            }
          />
        </div>,
      );
    }
  }

  // whitespace-nowrap px-3 py-4 text-xs text-gray-500 -space-x-3 w-fit hidden lg:fle

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return Avatars ? (
    <>
      <ReactTooltip
        place="top"
        effect="solid"
        backgroundColor="#1F2937"
        offset={{ right: 10 }}
        id={`${label}-members`}
      />
      <div
        className={classNames(
          'whitespace-nowrap px-3 py-1 text-xs text-gray-500 flex -space-x-4 items-center',
          className,
        )}
      >
        <span className="mr-8 font-bold text-sm" data-tip={labelTip} data-for={`${label}-members`}>
          {label}
        </span>
        {Avatars}
      </div>
    </>
  ) : null;
};
