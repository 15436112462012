import { QueryAllSkillsBasics } from '../../../graphql/skills';
import { useQuery } from '@apollo/client';
import SkillCard from '../../shared/SkillCard.component';
import React, { useState } from 'react';
import { useEffect, useRef, createRef } from 'react';
import { useDispatch } from 'react-redux';
import { toggleExplore } from '../../../redux/exploreSlice';

const AllSkillMenu = ({ show, input, position }) => {
  const { posX, posY } = position;
  const [itemHovering, setItemHovering] = useState(-1);
  const dispatch = useDispatch();

  const queryAllSkillsBasics = useQuery(QueryAllSkillsBasics, {
    variables: {
      where: {
        active: true,
      },
    },
  });

  const skills = queryAllSkillsBasics.data?.skills;
  const filteredSkills = skills?.filter((s) => s.slug.toLowerCase().includes(input?.toLowerCase()));

  const refs = useRef(Array.from({ length: filteredSkills?.length }, () => createRef()));

  useEffect(() => {
    refs.current = Array.from({ length: filteredSkills?.length }, () => createRef());
  }, [filteredSkills?.length]);

  let arrowKeyListener = null;

  useEffect(() => {
    setItemHovering(-1);
  }, [input]);

  useEffect(() => {
    const handleArrowKey = (e) => {
      if (show) {
        if (e.code === 'ArrowDown' || e.code === 'ArrowUp' || e.code === 'Enter') {
          e.preventDefault();
          console.log(filteredSkills);
          if (e.code === 'ArrowDown' && itemHovering < filteredSkills?.length - 1) {
            setItemHovering((itemHovering) => itemHovering + 1);
          } else if (e.code === 'ArrowUp' && itemHovering > 0) {
            setItemHovering((itemHovering) => itemHovering - 1);
          } else if (itemHovering >= 0 && e.code === 'Enter') {
            handleSelectSkill(filteredSkills[itemHovering]);
          }
        }
      }
    };
    arrowKeyListener = handleArrowKey;
    document.addEventListener('keydown', arrowKeyListener);
    return () => {
      document.removeEventListener('keydown', arrowKeyListener);
    };
  }, [show, itemHovering, filteredSkills]);

  useEffect(() => {
    if (refs.current[itemHovering]?.current)
      refs.current[itemHovering].current.scrollIntoView({ block: 'nearest' });
  }, [refs.current, itemHovering]);

  const handleSelectSkill = (s) => {
    dispatch(toggleExplore({ selectedSkill: s.slug }));
    setItemHovering(-1);
  };

  return (
    <div
      className={`fixed bg-white shadow-xl rounded-md z-50`}
      style={{
        top: `calc(${posY}px + 32px)`,
        left: `${posX}px`,
        overflow: 'auto',
      }}
    >
      {show ? (
        <ul className="w-64 h-fit max-h-60 overflow-y-auto overflow-x-hidden">
          {filteredSkills?.map((s, index) => (
            <li
              className="cursor-pointer"
              key={index}
              ref={refs.current[index]}
              onMouseEnter={() => {
                setItemHovering(index);
              }}
              onClick={() => {
                handleSelectSkill(s);
              }}
            >
              <SkillCard skill={s} highlight={itemHovering === index} />
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default AllSkillMenu;
