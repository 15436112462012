import { gql } from '@apollo/client';

export const QueryPersonBasics = gql`
  query QueryPersonBasics(
    $where: PersonWhere
    $orgUnitsConnectionWhere2: PersonOrgUnitsConnectionWhere
  ) {
    people(where: $where) {
      email
      name
      userIconUrl
      userBannerUrl
      cognitoID
      orgUnitsConnection(where: $orgUnitsConnectionWhere2) {
        edges {
          position
        }
      }
    }
  }
`;

export const QueryAllPeople = gql`
  query QueryAllPeople(
    $where: OrganizationWhere
    $membersWhere2: PersonWhere
    $orgUnitsConnectionWhere2: PersonOrgUnitsConnectionWhere
  ) {
    organizations(where: $where) {
      orgUnit {
        members(where: $membersWhere2) {
          name
          email
          userIconUrl
          google_workLocation
          orgUnitsConnection(where: $orgUnitsConnectionWhere2) {
            edges {
              position
            }
          }
        }
      }
    }
  }
`;

export const QueryCurrentOrg = gql`
  query QueryCurrentOrg($where: PersonWhere) {
    people(where: $where) {
      defaultOrgUid
      orgUnitsConnection {
        edges {
          node {
            logo
            motto
            name
            orgUnit_id
            organization {
              uid
              photoURL
              name
            }
          }
        }
      }
    }
  }
`;

export const QueryPeopleTablePagination = gql`
  query QueryPeopleTablePagination(
    $where: OrganizationWhere
    $membersWhere2: PersonWhere
    $options: PersonOptions
    $orgUnitsConnectionWhere2: PersonOrgUnitsConnectionWhere
  ) {
    organizations(where: $where) {
      orgUnit {
        members(where: $membersWhere2, options: $options) {
          name
          email
          cognitoID
          userIconUrl
          active
          google_workLocation
          locationState
          slackUsername
          isAdmin {
            uid
          }
          orgUnitsConnection(where: $orgUnitsConnectionWhere2) {
            edges {
              position
              startDate
              node {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const QueryPersonProfileGenerator = gql`
  query QueryPersonProfileGenerator(
    $where: PersonWhere
    $orgUnitsConnectionWhere2: PersonOrgUnitsConnectionWhere
    $experienceConnectionWhere2: PersonExperienceConnectionWhere
    $assessmentsWhere2: AssessmentCompletionWhere
  ) {
    people(where: $where) {
      name
      userIconUrl
      email
      orgUnitsConnection(where: $orgUnitsConnectionWhere2) {
        edges {
          position
          node {
            name
            orgUnit_id
          }
        }
      }
      biography
      attendedConnection {
        edges {
          degreeName
          majorName
          startDate
          endDate
          node {
            educationName
          }
        }
      }
      certs {
        name
        description
        category
      }
      skillsConnection {
        edges {
          rating
          node {
            name
            imageLink
            category(where: { type: "Category" }) {
              value
            }
          }
        }
      }
      experienceConnection(where: $experienceConnectionWhere2) {
        edges {
          startDate
          endDate
          totalHours
          hoursPerWeek
          role
          node {
            ... on Project {
              name
              company
              location
              industry
              sector
              startDate
              description
              endDate
              assessments(where: $assessmentsWhere2) {
                description
                skillsConnection {
                  edges {
                    rating
                    node {
                      name
                      imageLink
                      description
                      category(where: { type: "Category" }) {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
/*
{
  "where": null,
  "orgUnitsConnectionWhere2": null,
  "experienceConnectionWhere2": {
    "node": {
      "name_NOT_STARTS_WITH": null
    }
  },
  "assessmentsWhere2": {
    "personCompleted_SOME": {
      "cognitoID": null
    }
  },
}
*/

export const QueryPersonCertSimple = gql`
  query QueryPersonCertSimple(
    $where: PersonWhere
    $orgUnitsConnectionWhere2: PersonOrgUnitsConnectionWhere
  ) {
    people(where: $where) {
      biography
      active
      phone
      google_workLocation
      email
      linkedIn
      personalWebsite
      slackUsername
      name
      birthdate
      locationCity
      locationState
      locationZip
      userIconUrl
      userBannerUrl
      cognitoID
      certsConnection {
        edges {
          node {
            name
            level
            category
            logo
          }
          acquiredDate
        }
      }
      orgUnitsConnection(where: $orgUnitsConnectionWhere2) {
        edges {
          department
          startDate
          position
          node {
            logo
            name
            memberCount
            locationCity
            locationState
            motto
          }
        }
      }
    }
  }
`;

export const QueryPersonDetails = gql`
  query QueryPersonDetails(
    $where: PersonWhere
    $categoryConnectionWhere2: SkillCategoryConnectionWhere
    $experienceConnectionWhere2: PersonExperienceConnectionWhere
    $skillsConnectionWhere2: PersonSkillsConnectionWhere
    $orgUnitsConnectionWhere2: PersonOrgUnitsConnectionWhere
  ) {
    people(where: $where) {
      biography
      active
      phone
      google_workLocation
      email
      linkedIn
      personalWebsite
      slackUsername
      name
      birthdate
      locationCity
      locationState
      locationZip
      userIconUrl
      userBannerUrl
      cognitoID
      skillsConnection(where: $skillsConnectionWhere2) {
        edges {
          node {
            name
            imageLink
            slug
            categoryConnection(where: $categoryConnectionWhere2) {
              edges {
                node {
                  type
                  value
                }
              }
            }
          }
          rating
        }
      }
      certsConnection {
        edges {
          node {
            name
            level
            category
            logo
          }
          acquiredDate
        }
      }
      orgUnitsConnection(where: $orgUnitsConnectionWhere2) {
        edges {
          department
          startDate
          position
          node {
            logo
            name
            memberCount
            locationCity
            locationState
            motto
          }
        }
      }
      attendedConnection {
        edges {
          id
          degreeName
          startDate
          endDate
          majorName
          node {
            educationName
            attendedBy {
              email
              name
              userIconUrl
            }
          }
        }
      }
      experienceConnection(where: $experienceConnectionWhere2) {
        edges {
          active
          startDate
          endDate
          totalHours
          hoursPerWeek
          role
          node {
            ... on Project {
              name
              active
              startDate
              endDate
              description
              company
              location
              industry
              sector
            }
          }
        }
      }
    }
  }
`;

export const QueryPersonSkills = gql`
  query QueryPersonSkills($where: PersonWhere) {
    people(where: $where) {
      skillsConnection {
        edges {
          node {
            name
            imageLink
            description
            category {
              type
              value
            }
          }
          rating
        }
      }
    }
  }
`;

export const UpdatePersonDefaultOrgUid = gql`
  mutation UpdatePersonDefaultOrgUid($where: PersonWhere, $update: PersonUpdateInput) {
    updatePeople(where: $where, update: $update) {
      info {
        bookmark
      }
    }
  }
`;

export const QueryPersonWorksAt = gql`
  query QueryPersonWorksAt(
    $where: PersonWhere
    $isAdminConnectionWhere2: PersonIsAdminConnectionWhere
    $orgUnitsConnectionWhere2: PersonOrgUnitsConnectionWhere
  ) {
    people(where: $where) {
      isAdminConnection(where: $isAdminConnectionWhere2) {
        totalCount
      }
      orgUnitsConnection(where: $orgUnitsConnectionWhere2) {
        totalCount
      }
    }
  }
`;

export const CreateUserRecord = gql`
  mutation CreateUserRecord($input: [PersonCreateInput!]!) {
    createPeople(input: $input) {
      info {
        bookmark
      }
    }
  }
`;

export const UpdatePersonDetails = gql`
  mutation UpdatePersonDetails($where: PersonWhere, $update: PersonUpdateInput) {
    updatePeople(where: $where, update: $update) {
      people {
        name
        email
        userIconUrl
        userBannerUrl
      }
    }
  }
`;

export const UpdatePersonActiveStatus = gql`
  mutation UpdatePersonActiveStatus($where: PersonWhere, $update: PersonUpdateInput) {
    updatePeople(where: $where, update: $update) {
      people {
        email
        active
      }
    }
  }
`;

export const DeleteUserRecord = gql`
  mutation DeleteUserRecord($where: PersonWhere) {
    deletePeople(where: $where) {
      nodesDeleted
      relationshipsDeleted
    }
  }
`;
