/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { formatDate } from '../../lib/formatDate';
import TableView from '../custom/TableView.component';
import useTableViewHooks from '../custom/useTableViewHooks';
import PageContainer from '../../custom-prebuilt/PageContainer';
import { QueryCompletedAssessments } from '../../graphql/assessment';
import { useQuery } from '@apollo/client';
import defaultImg from '../../assets/default-user.svg';
import moment from 'moment';
import { useSelector } from 'react-redux';
import NoResults from '../../custom-prebuilt/NoResults.component';

export default function AssessmentCompletions(props) {
  const navigate = useNavigate();
  const { paginationSize = 15, actionName, search } = props;

  // Get query string from URL
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  // Sorting initial states
  const initialColumns = {
    user: 0,
    assessment: 0,
    lastCompletedDate: 0,
  };
  const [completedAssessments, setCompletedAssessments] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [completedAssessmentTotal, setCompletedAssessmentTotal] = useState();
  const [handleSortColumns] = useTableViewHooks('assessments', initialColumns);
  const sortSelector = useSelector((state) => state.sortTable.assessments);

  const assessment = useQuery(QueryCompletedAssessments, {
    variables: {
      where: {},
    },
  });
  const data = assessment.data?.assessmentCompletions;

  const tableHeadItems = [
    {
      table: 'assessments',
      name: 'user',
      title: 'User',
      sortable: true,
      sortingHandler: handleSortColumns,
      customClassName:
        'cursor-pointer py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6',
    },
    {
      table: 'assessments',
      name: 'assessment',
      title: 'Assessment',
      sortable: true,
      sortingHandler: handleSortColumns,
      customClassName:
        'py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 hidden lg:table-cell',
    },
    {
      table: 'assessments',
      name: 'lastCompletedDate',
      title: 'Last Completed Date',
      sortable: true,
      sortingHandler: handleSortColumns,
      customClassName: 'py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6',
    },
  ];

  useEffect(() => {
    if (data) {
      const maxDates = {};

      const newData = data
        .map((d) => {
          let user = d?.personCompleted?.[0];
          let assessment = d?.experienceAssessed?.[0] || d?.assessment?.[0];
          let entry = maxDates[user?.cognitoID + assessment?.name];
          let date = moment(d?.dateCompleted, 'MM-DD-YYYY');
          let maxDate = [date];
          if (entry) {
            maxDate = entry.concat(date);
          }
          maxDates[user?.cognitoID + assessment?.name] = maxDate;

          return {
            person: d?.personCompleted?.[0],
            assessment: assessment,
            dateCompleted: d?.dateCompleted,
          };
        })
        .filter((d) => {
          let entry = maxDates[d.person?.cognitoID + d.assessment?.name];
          if (entry) {
            d.dateCompleted = moment.max(entry);
            delete maxDates[d.person?.cognitoID + d.assessment?.name];
            return true;
          }
          return false;
        });

      let tmp = [...newData];
      if (sortSelector.column === 'user') {
        tmp.sort((a, b) => a.person.name.localeCompare(b.person.name));
      } else if (sortSelector.column === 'assessment') {
        tmp.sort((a, b) => a.assessment.name.localeCompare(b.assessment.name));
      } else if (sortSelector.column === 'lastCompletedDate') {
        tmp.sort((a, b) => b.dateCompleted - a.dateCompleted);
      }
      if (sortSelector.sortValue === 'DESC') {
        tmp.reverse();
      }

      if (searchInput.length) {
        tmp = tmp.filter((row) =>
          row.person?.name?.toLowerCase()?.includes(searchInput.toLowerCase()),
        );
      }

      const page = params.page ? params.page : 1;

      setCompletedAssessmentTotal(tmp.length);
      setCompletedAssessments(tmp.slice((page - 1) * paginationSize, page * paginationSize));
    }
  }, [data, params.page, sortSelector?.column, sortSelector?.sortValue, searchInput]);

  const RenderBody = useMemo(() => {
    const BodyMemo = () => {
      return (
        <>
          {completedAssessments &&
            completedAssessments.map((assess, index) => {
              const user = assess.person;
              const assessment = assess.assessment;
              return (
                <tr
                  key={index}
                  className="hover:bg-gray-200 transition duration-300 cursor-pointer"
                >
                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs sm:pl-6">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0">
                        <img
                          className="h-10 w-10 rounded-full"
                          src={user.userIconUrl ?? defaultImg}
                          alt=""
                          referrerPolicy="no-referrer"
                        />
                      </div>
                      <div className="ml-4">
                        <div className="font-medium text-gray-900">{user.name}</div>
                        <div className="text-gray-500">{user.email}</div>
                      </div>
                    </div>
                  </td>
                  <td className="px-4 py-3 text-xs text-gray-500 sm:pl-6 hidden lg:table-cell">
                    <div className="text-gray-900">{assessment?.name}</div>
                  </td>
                  <td className="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                    {formatDate(assess?.dateCompleted)}
                  </td>
                </tr>
              );
            })}
          {}
        </>
      );
    };

    return BodyMemo;
  }, [completedAssessments, navigate]);

  const navigateBack = () => {
    navigate('/assessment');
  };

  return (
    <PageContainer
      title="Completed Assessments"
      className="h-screen flex flex-col"
      actionName="Back to Assessment Directory"
      action={navigateBack}
    >
      <div className="mt-1 mb-4">
        <input
          type="text"
          placeholder="Find user"
          className="block w-full sm:max-w-sm rounded-md border-gray-300 shadow-sm focus:border-signature focus:ring-signature text-xs py-2"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>
      {!completedAssessments.length && searchInput.length ? (
        <NoResults searchInput={searchInput} />
      ) : (
        <TableView
          data={completedAssessments}
          tableHeadItems={tableHeadItems}
          paginationSize={paginationSize}
          RenderBody={RenderBody}
          showPaginationNav={true}
          page="assessments"
          renderActionCol={actionName}
          search={search}
          total={completedAssessmentTotal}
        />
      )}
    </PageContainer>
  );
}
