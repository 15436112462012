/*eslint-disable*/

import PersonCompareCard from './PersonCompareCard.component';
import { Droppable, Draggable } from '@hello-pangea/dnd';
import { useEffect, useState } from 'react';
import { Button, Input } from '../../../custom-prebuilt/common.component';
import { useSelector, useDispatch } from 'react-redux';
import CanvasToolbox from './tools/CanvasToolbox.component';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
  PencilAltIcon,
  CheckIcon,
} from '@heroicons/react/outline';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { toggleExplore } from '../../../redux/exploreSlice';

const SkillExploreCanvas = ({ canvasIdx }) => {
  const [revealAll, setRevealAll] = useState(false);
  const [editable, setEditable] = useState(false);
  const canvasItems = useSelector((state) => state.explore.canvasItems);
  const [parent] = useAutoAnimate({ duration: 150 });
  const [canvasNameParent] = useAutoAnimate({ duration: 150 });
  const dispatch = useDispatch();
  const canvasName = canvasItems[canvasIdx]?.[0];
  const [canvasNameInput, setCanvasNameInput] = useState(canvasName);

  const handleRevealAll = () => {
    setRevealAll(!revealAll);
  };

  const handleEditCanvasName = () => {
    setEditable(true);
  };

  const handleConfirmEditCanvasName = (e) => {
    e.preventDefault();
    dispatch(
      toggleExplore({
        canvasItems: [...canvasItems].map((canvas, idx) => {
          if (idx === canvasIdx) {
            const items = canvas[1];
            return [canvasNameInput || 'Canvas', items];
          } else {
            return canvas;
          }
        }),
      }),
    );
    setEditable(false);
  };

  const handleInputChange = (e) => {
    setCanvasNameInput(e.target.value);
  };

  const handleRemoveCanvas = () => {
    dispatch(
      toggleExplore({
        /* eslint-disable-next-line */
        canvasItems: [...canvasItems].filter((canvas, idx) => idx !== canvasIdx),
      }),
    );
  };

  return (
    <div ref={parent} className="h-full flex flex-col">
      {canvasItems[canvasIdx] && (
        <>
          <div className="flex flex-row items-center justify-between mb-4">
            <form
              onSubmit={handleConfirmEditCanvasName}
              ref={canvasNameParent}
              className="flex flex-row items-center space-x-2"
            >
              {editable ? (
                <Input
                  name="canvas-name"
                  id="canvas-name"
                  value={canvasNameInput}
                  onChange={handleInputChange}
                />
              ) : (
                <p className="text-xs font-semibold leading-6">{canvasName}</p>
              )}
              {editable ? (
                <CheckIcon
                  onClick={handleConfirmEditCanvasName}
                  className="h-4 w-4 cursor-pointer"
                />
              ) : (
                <PencilAltIcon onClick={handleEditCanvasName} className="h-4 w-4 cursor-pointer" />
              )}
            </form>
            {canvasItems[canvasIdx][1]?.length !== 0 && <CanvasToolbox className="ml-auto mr-4" />}
            <div className="space-x-3">
              <Button onClick={handleRevealAll}>
                {revealAll ? (
                  <ChevronUpIcon className="h-4 w-4" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4" />
                )}
              </Button>
              <Button onClick={handleRemoveCanvas} className="bg-red-600 hover:bg-red-700">
                <TrashIcon className="h-4 w-4" />
              </Button>
            </div>
          </div>
          <Droppable droppableId={canvasIdx.toString()} direction="horizontal">
            {(provided) => (
              <div
                className="h-full grid grid-cols-4 min-h-[92px] overflow-x-auto pt-2"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {canvasItems[canvasIdx][1]?.map((p, index) => (
                  <Draggable
                    key={index}
                    draggableId={`${canvasIdx}-${index.toString()}`}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        key={index}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        className="h-fit relative"
                      >
                        <PersonCompareCard
                          revealAll={revealAll}
                          personBasics={p}
                          className="mr-4 mb-4"
                          canvasIdx={canvasIdx}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </>
      )}
    </div>
  );
};

export default SkillExploreCanvas;
