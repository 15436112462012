import {
  InboxIcon,
  ChartSquareBarIcon,
  DocumentIcon,
  PencilAltIcon,
  UsersIcon,
} from '@heroicons/react/outline';
import { SlackOutlined, RadarChartOutlined } from '@ant-design/icons';
import { mergeClasses } from '../../../../lib/classNames';
import ReactTooltip from 'react-tooltip';
import { useEffect } from 'react';

const actions = [
  {
    name: 'Send email',
    icon: InboxIcon,
    action: null,
  },
  {
    name: 'Slack',
    icon: SlackOutlined,
    action: null,
  },
  {
    name: 'Team builder',
    icon: RadarChartOutlined,
    action: null,
  },
  {
    name: 'Assessment',
    icon: ChartSquareBarIcon,
    action: null,
  },
  {
    name: 'Profile Generator',
    icon: DocumentIcon,
    action: null,
  },
  {
    name: 'Create Group',
    icon: UsersIcon,
    action: null,
  },
  {
    name: 'Add Note',
    icon: PencilAltIcon,
    action: null,
  },
];

const CanvasToolbox = ({ className }) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <div
      className={mergeClasses(
        `w-fit transition duration-300 shadow rounded-sm bg-white flex flex-row`,
        className,
      )}
    >
      <ReactTooltip place="top" effect="solid" backgroundColor="#1F2937" id="tools" />
      {actions.map((action, index) => (
        <div className="cursor-pointer" key={index} data-tip={action.name} data-for="tools">
          <action.icon className="p-[0.375rem] h-8 w-8 border-x border-gray-100 hover:bg-gray-200 transition duration-150" />
        </div>
      ))}
    </div>
  );
};

export default CanvasToolbox;
