import { LocationMarkerIcon } from '@heroicons/react/outline';

import { Geo } from 'aws-amplify';
import { useEffect, useState } from 'react';
import LoadingState from '../../../../custom-prebuilt/preloader/LoadingState.component';
import { JGHQ_NY } from '../../../maps/geoData';
import Map from '../../../maps/Map';

const UserLocation = ({ userDetails }) => {
  const [coords, setCoords] = useState();
  const [loading, setLoading] = useState(true);

  /**Fetch coordinates from Geo API
   */
  useEffect(() => {
    async function FetchCoords() {
      if (userDetails?.locationCity && userDetails?.locationState)
        setCoords(
          (
            await Geo.searchByText(
              `${userDetails.locationCity} ${userDetails.locationState} ${userDetails.locationZip}`,
            )
          )[0].geometry?.point,
        );
      setLoading();
    }
    if (userDetails) FetchCoords();
  }, [userDetails]);

  return (
    <div className="bg-white shadow rounded-lg pt-5 px-5 flex flex-col space-y-4">
      <p className="text-xs font-semibold w-full flex items-center">Location</p>
      {loading ? (
        <>
          <div className="h-96 overflow-y-clip">
            <LoadingState />
          </div>
          <div className="bg-gray-200 w-[calc(100%+2.5rem)] py-3 px-4 -ml-5 rounded-b-md flex flex-row justify-between">
            <div className="flex flex-row space-x-2 items-center">
              <LocationMarkerIcon className="h-4 w-4" />
              <p className="text-xs font-medium">...</p>
            </div>
          </div>
        </>
      ) : coords ? (
        <>
          <div className="h-96 overflow-y-clip">
            <Map
              location={{
                latitude: coords[1],
                longitude: coords[0],
              }}
              pin={true}
            />
          </div>
          <div className="bg-gray-200 w-[calc(100%+2.5rem)] py-3 px-4 -ml-5 rounded-b-md flex flex-row justify-between">
            <div className="flex flex-row space-x-2 items-center">
              <LocationMarkerIcon className="h-4 w-4" />
              <p className="text-xs font-medium">
                Near {userDetails?.locationCity}, {userDetails?.locationState}
              </p>
            </div>
            <p className="text-xs font-normal">Find others nearby</p>
          </div>
        </>
      ) : (
        <>
          <div className="h-96 overflow-y-clip">
            <Map
              location={{
                latitude: JGHQ_NY.latitude,
                longitude: JGHQ_NY.longitude,
              }}
            >
              <div className="absolute bg-bg-dark bg-opacity-40 top-0 h-full  border-black w-full flex flex-col items-center">
                <p className="text-xl font-bold my-auto font-sans drop-shadow-xl drop-shadow-white">
                  This user hasn&apos;t specified a work region
                </p>
              </div>
            </Map>
          </div>
          <div className="bg-gray-200 w-[calc(100%+2.5rem)] py-3 px-4 -ml-5 rounded-b-md flex flex-row justify-between">
            <div className="flex flex-row space-x-2 items-center">
              <LocationMarkerIcon className="h-4 w-4" />
              <p className="text-xs font-medium">...</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserLocation;
