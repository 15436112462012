import { Input, Label, Button } from '../../../../custom-prebuilt/common.component';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { useAuth } from '../../../../lib/authContext';
import { QueryAllAnnouncements, CreateAnnouncement } from '../../../../graphql/announcements';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

const CreateAnnouncementPrompt = () => {
  const defaultOrgUid = useSelector((state) => state.org.defaultOrgUid);
  const { userEmail } = useAuth();
  const navigate = useNavigate();
  const [titleInput, setTitleInput] = useState('');
  const [descriptionInput, setDescriptionInput] = useState('');

  const handleTitleInputChange = (e) => {
    setTitleInput(e.target.value);
  };

  const handleDescriptionInputChange = (e) => {
    setDescriptionInput(e.target.value);
  };

  const [createAnnouncement] = useMutation(CreateAnnouncement, {
    refetchQueries: () => [
      {
        query: QueryAllAnnouncements,
        variables: {
          where: {
            draft: true,
          },
        },
      },
    ],
  });

  const handleCreateAnnouncement = () => {
    const announcementId = uuidv4();
    const input = [
      {
        active: true,
        draft: true,
        announcementId: announcementId,
        belongsTo: {
          connect: [
            {
              edge: {
                datePosted: moment().format('MM/DD/YYYY'),
              },
              where: {
                node: {
                  email: userEmail,
                },
              },
            },
          ],
        },
        organization: {
          connect: [
            {
              where: {
                node: {
                  uid: defaultOrgUid,
                },
              },
            },
          ],
        },
        content: '',
        description: descriptionInput,
        expiration: '',
        title: titleInput,
      },
    ];
    toast.promise(
      createAnnouncement({
        variables: {
          input: input,
        },
      }).then(() => {
        navigate(`announcements/${announcementId}/create`);
      }),
      {
        loading: 'Creating...',
        success: 'Successfully created',
        error: 'Could not created...',
      },
    );
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    handleCreateAnnouncement();
  };

  return (
    <form
      onSubmit={(e) => {
        handleSubmitForm(e);
      }}
    >
      <div>
        <Label>Title</Label>
        <Input
          placeholder="Announcement title"
          value={titleInput}
          onChange={(e) => {
            handleTitleInputChange(e);
          }}
          required
        />
        <Label>Description</Label>
        <Input
          placeholder="Announcement description"
          value={descriptionInput}
          onChange={(e) => {
            handleDescriptionInputChange(e);
          }}
          required
        />
        <Button className="w-full !py-3 mt-2">Create</Button>
      </div>
    </form>
  );
};

export default CreateAnnouncementPrompt;
