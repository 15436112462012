import SlideOver from '../../../custom-prebuilt/SlideOver.component';
import { toggleSlideOver } from '../../../redux/slideOverSlice';
import { useSelector, useDispatch } from 'react-redux';
import EditInfoContent from './EditInfoContent.component';

const EditInfo = () => {
  const show = useSelector((state) => state.slideOver.show1);
  const editObject = useSelector((state) => state.slideOver.editObject);
  const dispatch = useDispatch();

  const handleCloseSlideOver = () => {
    dispatch(toggleSlideOver({ show1: false }));
    setTimeout(() => {
      dispatch(toggleSlideOver({ editObject: null }));
    }, 300);
  };

  return (
    <div className="">
      <SlideOver
        show={show}
        title={`Edit: ${editObject?.field}`}
        onClose={handleCloseSlideOver}
        onClickButtonClose={handleCloseSlideOver}
        content={<EditInfoContent editObject={editObject} />}
      />
    </div>
  );
};

export default EditInfo;
