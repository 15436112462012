import { errorToastMid } from '../../../../lib/toast';
const userApi = process.env.REACT_APP_USER_API_URI;
const connectorAdminApi = process.env.REACT_APP_CONNECTORS_API_URI;

export function checkApiError(data) {
  if (
    data?.statusCode === 400 ||
    data?.statusCode === 403 ||
    data.message === 'Internal Server Error' ||
    data.message === 'Forbidden'
  ) {
    errorToastMid(data.message);
  }
}

function getHeaders(sessionToken) {
  return {
    'Content-Type': 'application/json',
    Authorization: 'Bearer ' + sessionToken,
    'Access-Control-Allow-Origin': '*',
  };
}

export async function api_adminCreateUser(sessionToken, email, cognitoID) {
  const headers = getHeaders(sessionToken);
  return fetch(userApi, {
    method: 'POST',
    body: `{"email": "${email}", "cognitoID": "${cognitoID}"}`,
    headers: headers,
  }).then((response) => response.json());
}

export async function api_editAdmin(sessionToken, email, action) {
  const headers = getHeaders(sessionToken);
  return fetch(`${userApi}/edit-admin`, {
    method: 'POST',
    body: `{"email": "${email}", "action": "${action}"}`,
    headers: headers,
  }).then((response) => response.json());
}

export async function api_adminDisableUser(sessionToken, email) {
  const headers = getHeaders(sessionToken);
  return fetch(`${userApi}/disable-user`, {
    method: 'POST',
    body: `{"email": "${email}"}`,
    headers: headers,
  }).then((response) => response.json());
}

export async function api_adminEnableUser(sessionToken, email) {
  const headers = getHeaders(sessionToken);
  return fetch(`${userApi}/enable-user`, {
    method: 'POST',
    body: `{"email": "${email}"}`,
    headers: headers,
  }).then((response) => response.json());
}

export async function api_adminDeleteUser(sessionToken, email) {
  const headers = getHeaders(sessionToken);
  return fetch(`${userApi}/delete-user`, {
    method: 'POST',
    body: `{"email": "${email}"}`,
    headers: headers,
  }).then((response) => response.json());
}

export async function api_reportBug(form, sessionToken) {
  const bugApi = process.env.REACT_APP_BUG_API_URI;

  return fetch(bugApi, {
    method: 'POST',
    body: form,
    headers: {
      Authorization: 'Bearer ' + sessionToken,
      'Access-Control-Allow-Origin': '*',
    },
  }).then(async (response) => {
    const body = await response.text();
    if (!response.ok) {
      console.error(body);
      throw new Error(body);
    } else {
      return body;
    }
  });
}

//returns all connectors in the DynamoDB table
export async function api_scanConnectors(sessionToken) {
  const headers = getHeaders(sessionToken);
  return fetch(connectorAdminApi, { method: 'GET', headers: headers }).then((res) => res.json());
}

//creates a new connector or updates an existing connector
export async function api_putConnector(sessionToken, connector) {
  const headers = getHeaders(sessionToken);
  return fetch(connectorAdminApi, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(connector),
  }).then((res) => res.json());
}

export async function api_getConnectorLogs(sessionToken, connectorId, nextToken) {
  const headers = getHeaders(sessionToken);
  // Fetch logs the first time
  if (!nextToken) {
    return fetch(`${connectorAdminApi}/logs/${connectorId}`, {
      method: 'GET',
      headers: headers,
    }).then((res) => res.json());
  }
  // Fetch more logs
  else {
    return fetch(`${connectorAdminApi}/logs/${connectorId}?nextToken=${nextToken}`, {
      method: 'GET',
      headers: headers,
    }).then((res) => res.json());
  }
}

export async function api_triggerConnector(sessionToken, connector) {
  const headers = getHeaders(sessionToken);
  return fetch(`${connectorAdminApi}/trigger`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({ ...connector }),
  }).then((res) => res);
}
