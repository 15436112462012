import { useDispatch, useSelector } from 'react-redux';
import { EXPERIENCE, OVERVIEW, SKILLS, toggleProfilenav } from '../../redux/profileNavSlice';
import { NavBar, NavTab } from '../../custom-prebuilt/common.component';

const tabs = [OVERVIEW, SKILLS, EXPERIENCE];

const ProfileNav = () => {
  const dispatch = useDispatch();
  const current = useSelector((state) => state.profileNav.current);

  return (
    <NavBar>
      {tabs.map((tab) => (
        <NavTab
          key={tab}
          label={tab}
          active={tab === current}
          onClick={() => {
            dispatch(toggleProfilenav({ current: tab }));
          }}
        />
      ))}
    </NavBar>
  );
};

export default ProfileNav;
