import { useNavigate } from 'react-router';

const UserSkillCategory = ({ skillCategoryList }) => {
  const navigate = useNavigate();

  return (
    <>
      {skillCategoryList?.map((skill, index) => {
        return (
          <div
            key={index}
            className="rounded-lg shadow px-2 md:px-5 py-4 mb-2 flex flex-row h-fit items-center justify-between bg-white
                cursor-pointer hover:bg-gray-900 hover:text-white transition duration-150"
            onClick={() => {
              navigate(`/skills/${skill.node.slug}`);
            }}
          >
            <div className="flex flex-row h-fit items-center">
              <img
                className="h-8 w-8 xl:h-8 xl:w-8 select-none mr-2 sm:mr-3"
                src={`${process.env.REACT_APP_CLOUDFRONT_PUBLIC}/public${skill.node.imageLink}`}
                alt=""
              />
              <p className="text-xs font-normal">{skill.node.name}</p>
            </div>
            <div className="flex flex-row items-center">
              <p className="text-xs font-light">
                {skill.rating === 1
                  ? 'Knowledgable'
                  : skill.rating === 2
                  ? 'Proficient'
                  : 'Lead/Teach'}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default UserSkillCategory;
