import { useAutoAnimate } from '@formkit/auto-animate/react';
import { Button, Input, TextArea } from '../../../custom-prebuilt/common.component';
import { useHorizontalTabs } from '../../../lib/customHook';
import { Toggle } from '../../../custom-prebuilt/common.component';
import { useState, useEffect } from 'react';
import {
  QuerySkillTags,
  CreateSkill,
  QueryAllSkillsBasics,
  QuerySkill,
} from '../../../graphql/skills';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import moment from 'moment';
import { Amplify, Storage } from 'aws-amplify';
import Resizer from 'react-image-file-resizer';
import { errorToastMid } from '../../../lib/toast';
import ImageInput from '../../../custom-prebuilt/ImageInput';
import { v4 as uuidv4 } from 'uuid';

const tabs = ['Step 1', 'Step 2', 'Step 3'];

Amplify.configure({
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_PUBLIC, //REQUIRED -  Amazon S3 bucket name
      region: process.env.REACT_APP_COGNITO_USER_POOL_REGION, //OPTIONAL -  Amazon service region
    },
  },
});

const AddNewSkill = (props) => {
  const [current, setCurrent, tabsComponent] = useHorizontalTabs(tabs, true);
  const [parent] = useAutoAnimate();

  const [skillTags, setSkillTags] = useState([]);
  const [filteredSkillTags, setFilteredSkillTags] = useState([]);
  const [name, setName] = useState('');
  const [image, setImage] = useState(null);
  const [description, setDescription] = useState('');
  const [website, setWebsite] = useState('');
  const [selectedSkillTags, setSelectedSkillTags] = useState([]);
  const [active, setActive] = useState(true);
  const [loadingCreateSkill, setLoadingCreateSkill] = useState(false);

  const [querySkill] = useLazyQuery(QuerySkill);
  const [createSkill] = useMutation(CreateSkill, {
    refetchQueries: [
      {
        query: QueryAllSkillsBasics,
      },
    ],
  });

  const querySkillTags = useQuery(QuerySkillTags);

  useEffect(() => {
    querySkillTags?.data?.skillTags && setSkillTags(querySkillTags?.data?.skillTags);
  }, [querySkillTags?.data]);

  useEffect(() => {
    const skillTagsType = [];
    skillTags.map(
      (st) =>
        !skillTagsType.includes(st.type) && st.type !== 'Stack' && skillTagsType.push(st.type),
    );
    setFilteredSkillTags(
      skillTagsType.map((t) => {
        const temp = { type: t, value: [] };
        skillTags.map((st) => st.type === t && temp.value.push(st.value));
        return temp;
      }),
    );
  }, [skillTags]);

  const handleSelectSkillTag = (type, value) => {
    const selected = { type, value };
    selectedSkillTags.map((st) => st.value).includes(value)
      ? setSelectedSkillTags((selectedSkillTags) =>
          selectedSkillTags.filter((st) => st.value !== value),
        )
      : setSelectedSkillTags((selectedSkillTags) => [...selectedSkillTags, selected]);
  };

  const getCategoryInput = () => {
    return selectedSkillTags.map((s) => {
      return {
        where: {
          node: {
            value: s.value,
          },
        },
      };
    });
  };

  const getSlug = () => {
    var slug = name.replace(/\W+/g, '-');
    slug = slug.replaceAll('_', '-'); //Handles previous rejex with underscore problem
    slug = slug.replaceAll('+', '-plus');
    if (slug[slug.length - 1] === '-') slug = slug.slice(0, -1); //Removes extra dash if user puts space at end

    return slug.toLowerCase();
  };

  const handleInputChange = (e) => {
    switch (e.target.name) {
      case 'name':
        setName(e.target.value);
        break;
      case 'description':
        setDescription(e.target.value);
        break;
      case 'website':
        setWebsite(e.target.value);
        break;
      default:
        break;
    }
  };

  const handleNextStage = () => {
    switch (current) {
      case 0:
        if (!name || !description || !website || !image) {
          let missings = [];
          if (!name) {
            missings.push('Name ');
          }
          if (!description) {
            missings.push('Description ');
          }
          if (!website) {
            missings.push('Website Link');
          }
          if (!image) {
            missings.push('Image ');
          }
          errorToastMid(`Missing input(s): ${missings.toString()}. Please check again`);
        } else {
          setCurrent((current) => current + 1);
        }
        break;
      case 1:
        if (!selectedSkillTags.length) {
          errorToastMid('Please select at least 1 skill tag');
        } else {
          setCurrent((current) => current + 1);
        }
        break;
      default:
        break;
    }
  };

  const resizeFile = (file, maxWidth, maxHeight, compressFormat, quality) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file, // Is the file of the image which will resized.
        maxWidth, // Is the maxWidth of the resized new image.
        maxHeight, // Is the maxHeight of the resized new image.
        compressFormat, // Is the compressFormat of the resized new image.
        quality, // Is the quality of the resized new image.
        0, // Is the degree of clockwise rotation to apply to uploaded image.
        (uri) => {
          // Is the callBack function of the resized new image URI.
          resolve(uri);
        },
        'file', // Is the output type of the resized new image. Can be either base64, blob or file.(Default type is base64)
        0, // Is the minWidth of the resized new image.
        0, // Is the minHeight of the resized new image.
      );
    });

  const handleCreateSkill = async () => {
    setLoadingCreateSkill(true);
    const resizedImage = await resizeFile(image, 200, 200, 'png', 100);
    const slug = getSlug();

    const { data } = await querySkill({
      variables: { where: { slug: slug } },
      fetchPolicy: 'network-only',
    });
    if (data?.skills?.length) {
      errorToastMid('Skill already exists');
      return;
    }

    const createPromise = Storage.put(`SkillLogos/${slug}_${resizedImage.name}`, resizedImage, {
      level: 'public',
      bucket: `${process.env.REACT_APP_S3_PUBLIC}`,
    })
      .then(() => {
        return createSkill({
          variables: {
            input: [
              {
                name: name,
                description: description,
                active: active,
                websiteLink: website,
                id: uuidv4(),
                imageLink: `/SkillLogos/${slug}_${resizedImage.name}`,
                slug: slug,
                dateAdded: moment().format('MM/DD/YYYY'),
                category: {
                  connect: getCategoryInput(),
                },
              },
            ],
          },
        });
      })
      .then(() => {
        setCurrent(0);
        setImage(null);
        setName('');
        setDescription('');
        setWebsite('');
        setSelectedSkillTags([]);
        setActive(true);
        props?.reload?.();
      });
    toast.promise(createPromise, {
      success: 'Successfully created skill!',
      error: 'An error occurred!',
    });
  };

  useEffect(() => {
    if (current == 0) {
      setLoadingCreateSkill(false);
    }
  }, [current]);

  return (
    <div className="flex-grow lg:px-5 mt-5 lg:mt-0">
      <h2 className="text-sm font-medium text-gray-900 mb-2 ml-1">Add New Skill</h2>
      <div ref={parent} className="w-full lg:max-w-lg p-6 bg-white shadow rounded-lg">
        {tabsComponent}
        {current === 0 && (
          <div className="flex flex-col space-y-2">
            <p className="text-xs text-font-light font-semibold">Add basic skill info</p>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Name</label>
              <Input
                className="border px-2"
                name="name"
                value={name}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Description</label>
              <TextArea
                className="border px-2 resize-y h-24"
                value={description}
                name="description"
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </div>
            <div className="flex flex-col space-y-1">
              <label className="text-xs">Website Link</label>
              <Input
                className="border px-2"
                name="website"
                value={website}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </div>
            <div className="flex justify-between w-full pt-2">
              <ImageInput
                image={image}
                setImage={setImage}
                enableCropper
                cropShowGrid
                cropShape={'round'}
                cropAspect={1}
                contentWrapperClasses="flex items-center space-x-4 pt-1"
                displayImageClasses="h-[84px] w-[84px] rounded-full bg-white border-2 p-1 border-gray-800"
                changeButtonText="Upload Image"
              />
              <div className="flex flex-col space-y-1 mb-2">
                <label className="text-xs">Active</label>
                <div className="flex flex-row">
                  <Toggle
                    boolean={active}
                    checked={active}
                    onChange={() => {
                      setActive(!active);
                    }}
                  />
                  <p className="text-xs ml-2">{active ? 'true' : 'false'}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {current === 1 && (
          <div className="flex flex-col space-y-2">
            <p className="text-xs text-font-light font-semibold">
              Select skill tags from both categories
            </p>
            <div className="flex flex-col space-y-4">
              {filteredSkillTags.map((type, index) => (
                <div key={index}>
                  <p className="text-xs font-semibold mb-2">{type.type}</p>
                  <div className="flex flex-row flex-wrap">
                    {type.value.map((value, index) => (
                      <p
                        key={index}
                        className={`px-2 py-1 mr-1 mb-1 text-xs transition duration-300 cursor-pointer
                            ${
                              selectedSkillTags.map((s) => s.value).includes(value)
                                ? 'bg-gray-900 text-white'
                                : 'bg-gray-200 text-gray-600'
                            }`}
                        onClick={() => {
                          handleSelectSkillTag(type, value);
                        }}
                      >
                        {value}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {current === 2 && (
          <div className="flex flex-col space-y-2">
            <p className="text-xs text-font-light font-semibold">Confirm details</p>
            <div className="flex flex-col space-y-1">
              <p className="text-xs font-medium text-black">
                Name: <span className="text-gray-500">{name}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Description: <span className="text-gray-500">{description}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Website link: <span className="text-gray-500">{website}</span>
              </p>
              <p className="text-xs font-medium text-black">
                Active: <span className="text-gray-500">{active}</span>
              </p>
              <div className="text-xs font-medium text-black flex flex-row flex-wrap">
                <p className="mr-2">Category:</p>
                {selectedSkillTags.map((st, index) => (
                  <p
                    key={index}
                    className="px-2 py-1 mr-1 mb-1 text-xs transition duration-300 cursor-pointer
                      bg-gray-200 text-gray-600"
                  >
                    {st.value}
                  </p>
                ))}
              </div>
              <div className="flex flex-col">
                <span className="text-xs font-medium text-black">Image:</span>
                <img
                  className="mt-2 ml-4 h-[84px] w-[84px] rounded-full bg-white border-2 p-1 border-gray-800"
                  src={URL.createObjectURL(image)}
                  alt="logo"
                />
              </div>
            </div>
          </div>
        )}

        <div className="w-full justify-end flex flex-row space-x-1 mt-4">
          {current !== 0 && (
            <Button
              onClick={() => {
                setCurrent((current) => current - 1);
              }}
            >
              Back
            </Button>
          )}
          {current !== 2 && (
            <Button
              onClick={() => {
                handleNextStage();
              }}
            >
              Next
            </Button>
          )}
          {current === 2 && (
            <Button
              onClick={handleCreateSkill}
              disabled={loadingCreateSkill}
              // disabled={true}
            >
              Create
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewSkill;
