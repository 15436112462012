import React, { Fragment } from 'react';
import RestrictedAuth from '../custom-prebuilt/RestrictedAuth.component';
import { useSelector } from 'react-redux';

const RoleRestrictedRoute = ({ children }) => {
  const isAdmin = useSelector((state) => state.org.isCurrentAdmin);
  // For now this only includes admin, but eventually once more roles are implemented we will
  // pass in a role(s) param to verify the user has the required role
  if (!isAdmin) {
    return <RestrictedAuth />;
  }
  return <Fragment>{children}</Fragment>;
};

export default RoleRestrictedRoute;
