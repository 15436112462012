import {
  BoldOutlined,
  ItalicOutlined,
  StrikethroughOutlined,
  CodeOutlined,
  UnorderedListOutlined,
  OrderedListOutlined,
  CodeSandboxOutlined,
  LineOutlined,
  UndoOutlined,
  RedoOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  AlignCenterOutlined,
  SaveOutlined,
} from '@ant-design/icons';

import { iconToastMid } from '../../../lib/toast';

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div
      className="flex flex-row border-2 border-gray-200 w-fit max-w-full flex-wrap sticky 
        top-[calc(68px+5rem)] md:top-[calc(84px+4rem)] z-40 bg-white h-fit
          shadow-lg"
    >
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={`${editor.isActive('bold') ? 'is-active' : ''} editor-menu-btn`}
      >
        <BoldOutlined />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={`${editor.isActive('italic') ? 'is-active' : ''} editor-menu-btn`}
      >
        <ItalicOutlined />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={`${editor.isActive('strike') ? 'is-active' : ''} editor-menu-btn`}
      >
        <StrikethroughOutlined />
      </button>
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={`${editor.isActive('paragraph') ? 'is-active' : ''} editor-menu-btn`}
      >
        <p className="font-semibold">T</p>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={`${editor.isActive('heading', { level: 1 }) ? 'is-active' : ''} editor-menu-btn`}
      >
        <p className="font-semibold">h1</p>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={`${editor.isActive('heading', { level: 2 }) ? 'is-active' : ''} editor-menu-btn`}
      >
        <p className="font-semibold">h2</p>
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={`${editor.isActive('blockquote') ? 'is-active' : ''} editor-menu-btn`}
      >
        <p className="font-semibold">&quot; &quot;</p>
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign('left').run()}
        className={`${editor.isActive({ textAlign: 'left' }) ? 'is-active' : ''} editor-menu-btn`}
      >
        <AlignLeftOutlined />
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign('center').run()}
        className={`${editor.isActive({ textAlign: 'center' }) ? 'is-active' : ''} editor-menu-btn`}
      >
        <AlignCenterOutlined />
      </button>
      <button
        onClick={() => editor.chain().focus().setTextAlign('right').run()}
        className={`${editor.isActive({ textAlign: 'right' }) ? 'is-active' : ''} editor-menu-btn`}
      >
        <AlignRightOutlined />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`${editor.isActive('bulletList') ? 'is-active' : ''} editor-menu-btn`}
      >
        <UnorderedListOutlined />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`${editor.isActive('orderedList') ? 'is-active' : ''} editor-menu-btn`}
      >
        <OrderedListOutlined />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        className={`${editor.isActive('code') ? 'is-active' : ''} editor-menu-btn`}
      >
        <CodeOutlined />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={`${editor.isActive('codeBlockLowLight') ? 'is-active' : ''} editor-menu-btn`}
      >
        <CodeSandboxOutlined />
      </button>
      <button
        onClick={() => editor.chain().focus().setHorizontalRule().run()}
        className="editor-menu-btn"
      >
        <LineOutlined />
      </button>
      <button onClick={() => editor.chain().focus().undo().run()} className="editor-menu-btn">
        <UndoOutlined />
      </button>
      <button onClick={() => editor.chain().focus().redo().run()} className="editor-menu-btn">
        <RedoOutlined />
      </button>
      <button
        onClick={() => {
          iconToastMid('Connect auto save your work');
        }}
        className="editor-menu-btn"
      >
        <SaveOutlined />
      </button>
    </div>
  );
};

export default MenuBar;
